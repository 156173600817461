import Vue from 'vue'
import VueRouter from 'vue-router'
import Inventario from '../view/Inventario.vue'
import Equipos from '../view/Equipos.vue'
import Padron from '../view/Padron.vue'
import Permiso from '../view/Permiso.vue'
import Soap from '../view/Soap.vue'
import Poliza from '../view/Poliza.vue'
import Revision from '../view/Revision.vue'
import Trabajo from '../view/Trabajo.vue'
import Alertas from '../view/Alertas.vue'
import Proyectos from '../view/Proyectos.vue'
import Usuarios from '../view/Usuarios.vue'
import Login from '../view/Login.vue'
import Cotizaciones from '../view/Cotizaciones.vue'
Vue.use(VueRouter)

const routes = [
    {
        path:'/Inventario',
        name:'Inventario',
        component: Inventario
    },
    {
        path:'/Equipos',
        name:'Equipos',
        component: Equipos
    },
    {
        path:'/padron',
        name:'Padron',
        component: Padron
    },
    {
        path:'/permiso',
        name:'Permiso',
        component: Permiso
    },
    {
        path:'/soap',
        name:'Soap',
        component: Soap
    },
    {
        path:'/poliza',
        name:'Poliza',
        component: Poliza
    },
    {
        path:'/revision',
        name:'Revision',
        component: Revision
    },
    {
        path:'/trabajos',
        name:'Trabajo',
        component: Trabajo
    },
    {
        path:'/proyectos',
        name:'Proyectos',
        component: Proyectos
    },
    {
        path:'/usuarios',
        name:'Usuarios',
        component: Usuarios
    },
    {
        path:'/alertas',
        name:'Alertas',
        component: Alertas
    },
    {
        path:'/cotizaciones',
        name:'Cotizaciones',
        component: Cotizaciones
    },
    {
        path:'/',
        name:'/',
        component: Login
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
export default router