    <template>
    <div class="content">
        <nav class="navbar navbar-expand-lg navbar-light shadow d-print-none" style="background-color: rgb(255,255,255);">
            <a class="navbar-brand" >
                <img width="150px" style="margin-left: 1%;" class="img-fluid" src="../assets/logo.jpeg">

            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
                style="color: white;background-color: white; margin-right: 1%;">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown" style="font-size: large;">
                 <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" href="/alertas"><b-icon style=" color:#27AE4A" icon="house"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Alertas</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/trabajos"><b-icon style=" color:#27AE4A" icon="tools"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Trabajos</span></a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="/equipos"><b-icon style=" color:#27AE4A" icon="truck"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A;"> Equipos</span></a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="/inventario"><b-icon style=" color:#27AE4A" icon="box-seam"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Inventario</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/proyectos"><b-icon style=" color:#27AE4A" icon="briefcase"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Proyectos</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/usuarios"><b-icon style=" color:#27AE4A" icon="person"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Usuarios</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/cotizaciones"><b-icon style=" color:#27AE4A" icon="file-ruled"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Cotizaciones</span></a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="container-fluid col-md-11 shadow mt-3" style="background-color: white; border-radius: 10px;">
            <div class="d-flex justify-content-center">
                <h1 style="color:#26AF4A;">Poliza</h1>
            </div>
            <div class="table-responsive" id="tabla">
                
            </div>
        
             <iframe id="pdf" width="100%" height="600px"></iframe>
              <div class="d-flex justify-content-center" style="margin-top: 1%;padding-bottom: 1%;">
                         <div class="form-group">
                        <label>FECHA VENCIMIENTO</label>
                        <input type="date" id="fechaVencimiento" style="border-color:green"  class="form-control"  v-model="fechaVencimiento" required>
                        </div>
                    
            </div>
             <div class="d-flex justify-content-center" style="margin-top: 1%;padding-bottom: 1%;">
                         <div class="form-group">
                             <label>Seleccionar archivo</label>
                        <input style="border-color:rgb(237,126,37)" id="archivo" @change="clickImagen($event)" type="file" accept="file/*" ><br>
                </div>
                    
            </div>
            <div class="d-flex justify-content-center" style="margin-top: 1%;padding-bottom: 1%;">
                <button @click="subir()" class="btn" style="background-color: #26AF4A;color: white;">
                    Subir
                </button>
            </div>
        </div>
       
    </div>
</template>

<script>

import Swal from 'sweetalert2'
//import { db } from '../firebaseDb';
import {storage} from '../firebaseDb';
const ref=storage.ref()
export default {

    name: 'Inventario',
    components: {

    },
    computed: {

    },
    data: function () {
        return {
            imagen:null,
            nombreImg:'',
            patente:'',
             urlBd:'https://api.trosales.cl/',
            tk:'',
           usuario:''
           // urlBd:'http://localhost:3000/',
        }
    },
    methods: {
        ver(){
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const patente = urlParams.get('patente');
            const archivo = urlParams.get('archivo');
            this.patente=patente
            console.log(patente,archivo)
            if(archivo){
                console.log("cargar en pdf")
                this.verArchivo(archivo)
            }else{
                console.log("no hay")
            }
        },

        verArchivo(imagen) {
      //console.log(imagen);
      document.getElementById("pdf").src = "";
     // console.log(imagen);
      const refImg = ref
        .child("poliza/" + imagen)
        .getDownloadURL()
        .then(function (url) {
          console.log(refImg);

          var img = document.getElementById("pdf");
          img.src = url;
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire({
            title: "Error",
            text: "No hay imagen asociada",
            icon: "error",
          });
        });
    },
        clickImagen(e){
             this.imagen=e.target.files[0];  
              // console.log(this.imagen)
        },

        subirImagen(nombreImg){
            //referencia de subida
                const refImg=ref.child('poliza/'+nombreImg)
                const metadata={contentType:'application/pdf'}
                refImg.put(this.imagen,metadata)
                .then(e=>console.log(e))
                    document.getElementById("archivo").value="";
                    this.imagen=null;
                //setTimeout(this.verFoto, 5000,""); 
                    
        },
        subir(){
            var fecha=document.getElementById("fechaVencimiento").value
            var nombreImg='POLIZA'+this.patente.toUpperCase()+this.imagen.name;
            console.log(fecha,nombreImg)
            this.subirImagen(nombreImg)
            const datos = {
                patente:this.patente,
                archivo:nombreImg,
                fecha: fecha,

            }
            const url = this.urlBd+'actualizarPoliza'
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(datos)
            }

            fetch(url, reqOpciones).then(res => {
                console.log(res)
                
                res.json().then(data => {
                    
                    Swal.fire({
                        icon: 'success',
                        title: 'Poliza Cargada'
                    })
                    this.$router.push('/equipos');
                    console.log(data)
                })
                
            }) 
                     
        },
    },
    mounted() {
         var token = localStorage.getItem("token");
          var tipo = localStorage.getItem("tipo");
           this.tk=localStorage.getItem("token");
           this.usuario=localStorage.getItem("usuario");
         // console.log(token,tipo); 
          if(!token || tipo!=0){
            this.$router.push('/');
          }
        this.ver()
    }
}
</script>

<style>
h1 {
    color: #26AF4A;
    font-weight: bold;
    font-size: xx-large;
}

h2 {
    color: #26AF4A;
    font-weight: bold;
    font-size: x-large;
}

span {
    color: #26AF4A;
    font-size: large;
    font-weight: 500;
}
</style>