import { render, staticRenderFns } from "./Equipos.vue?vue&type=template&id=2f349780&"
import script from "./Equipos.vue?vue&type=script&lang=js&"
export * from "./Equipos.vue?vue&type=script&lang=js&"
import style0 from "./Equipos.vue?vue&type=style&index=0&id=2f349780&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports