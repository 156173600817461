<template>
<div>
    <div class="d-print-none d-block">
    <div class="content">
        <nav class="navbar navbar-expand-lg navbar-light shadow d-print-none" style="background-color: rgb(255,255,255);">
            <a class="navbar-brand">
                <img width="150px" style="margin-left: 1%;" class="img-fluid" src="../assets/logo.jpeg">

            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
                style="color: white;background-color: white; margin-right: 1%;">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown" style="font-size: large;">
               <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" href="/alertas"><b-icon style=" color:#27AE4A" icon="house"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Alertas</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/trabajos"><b-icon style=" color:#27AE4A" icon="tools"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Trabajos</span></a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="/equipos"><b-icon style=" color:#27AE4A" icon="truck"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A;"> Equipos</span></a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="/inventario"><b-icon style=" color:#27AE4A" icon="box-seam"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Inventario</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/proyectos"><b-icon style=" color:#27AE4A" icon="briefcase"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Proyectos</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/usuarios"><b-icon style=" color:#27AE4A" icon="person"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Usuarios</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/cotizaciones"><b-icon style=" color:#27AE4A" icon="file-ruled"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Cotizaciones</span></a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="container-fluid col-md-11 shadow mt-3" style="background-color: white; border-radius: 10px;">
           <div class="d-flex justify-content-center">
                <h1 style="color:#26AF4A;"><b>Ordenes de Trabajo</b></h1>
            </div>
             <div id="buscarNombre" >
            <div class="d-flex justify-content-center" >
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                        <label>PATENTE</label>
                        <input type="text" class="form-control" v-model="patenteBusqueda" id="patenteBuscar" placeholder="Ingrese patente" style="border: 1px #26AF4A  solid;" required >
                        </div>
                    </div>
                    <div class="col-sm-3">
                         <div class="form-group">
                        <label>FECHA DESDE</label>
                        <input type="date" id="fecha1" v-model="fecha1Busqueda"  class="form-control"  style="border: 1px #26AF4A  solid;"  required>
                        </div>
                    </div>
                    <div class="col-sm-3">
                         <div class="form-group">
                        <label>FECHA HASTA</label>
                        <input type="date" id="fecha2" v-model="fecha2Busqueda"  class="form-control"  style="border: 1px #26AF4A  solid;"  required>
                        </div>
                    </div>
                    <div class="col-sm"><br>
                         <button type="submit" id="btnBuscar" style="background-color:#27AE4A" class="btn btn-success" @click="buscarOrdenTrabajo()" >
                        
                        <i class="fas fa-search"></i>
                    </button>
                    </div>
                </div>
            </div><br>
        </div>
        
        <div class="d-block text-center">
    <button type="button" id="imprimir" @click="imprimir()" class="btn btn-warning btn-sm" style="display:none">Imprimir</button>
     </div>
            <div class="table-responsive" id="tabla">
                <table class="table table-striped table-bordered">
                    <thead style="position: sticky;top: 0">
                        <tr>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">Patente
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Tipo</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Modelo</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Marca</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Año</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                KM</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                HM</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Observación</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Items</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Fecha</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Mecánico</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Usuario</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Mantenciones">
                            <td>{{ datos.patente }}</td>
                            <td>{{ datos.tipo }}</td>
                            <td>{{ datos.modelo }}</td>
                            <td>{{ datos.marca }}</td>
                            <td>{{ datos.año }}</td>
                            <td>{{ datos.kilometraje }}</td>
                            <td>{{ datos.horometro }}</td>
                            <td>{{ datos.observacion }}</td>
                             <td>{{ datos.items }}</td>
                            <td>{{ datos.fechaMantencion}}</td>
                             <td>{{ datos.mecanico}}</td>
                            <td>{{ datos.usuario}}</td>
                           
                        </tr>
                    </tbody>
                </table>
            </div>
              <div class="d-flex justify-content-center">
              <div class="row">
                    <div class="col">
                        <button v-b-modal.modal-AgregarOrden class="btn" @click="nuevaOrden()"
                            style="background-color: #26AF4A;color: white;width: 100%;">
                            Agregar Orden de Trabajo
                        </button>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 1%;padding-bottom: 1%;">
                

            </div>
        </div>
        <b-modal id="modal-verItems" size="lg" hide-footer  centered>
           <div class="table-responsive" id="tabla">
                <table class="table table-striped table-bordered">
                    <thead style="position: sticky;top: 0">
                        <tr>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">Cod. Item
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Nombre</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Cantidad</th>
                            
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in itemsMantencion">
                            <td>{{ datos.id }}</td>
                            <td>{{ datos.nombre }}</td>
                            <td>{{ datos.cantidad }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <b-modal id="modal-AgregarOrden" size="xl" hide-footer hide-header>
            <form @submit.prevent="agregarOrdenTrabajo()">
                <div class="d-flex justify-content-center">
                    <h2 style="color:#26AF4A; font-weight: bold;">Agregar Orden de Trabajo</h2>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Patente</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <div class="input-group">
                                    <input type="text" class="form-control form-control-lg"
                                        v-model="patente" maxlength="8" 
                                        aria-describedby="basic-addon2" style="border: 1px #26AF4A  solid;">
                                    <div class="input-group-append">
                                        <button @click="buscarPatente()" class="btn btn-outline-secondary btn-lg"
                                            type="button"
                                            style="border: 1px #26AF4A solid; color:white;background-color: #26AF4A;border-top-left-radius: 0; border-bottom-left-radius: 0;">
                                            <b-icon icon="search" aria-hidden="true"></b-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Equipo</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="text" id="equipo" class="form-control form-control-lg" name="equipo" readonly
                                    v-model="equipo" style="border: 1px #26AF4A  solid;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Kilometraje Actual</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="text" id="kilometrajeActual" class="form-control form-control-lg"
                                    name="kilometrajeActual" required v-model="kilometrajeActual"
                                    style="border: 1px #26AF4A  solid;" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Kilometraje Nuevo</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="text" id="kilometrajeNuevo" class="form-control form-control-lg"
                                    name="kilometrajeNuevo" required v-model="kilometrajeNuevo"
                                    style="border: 1px #26AF4A  solid;">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Horometro Actual</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="text" id="horometroActual" class="form-control form-control-lg"
                                    name="horometroActual" required v-model="horometroActual"
                                    style="border: 1px #26AF4A  solid;" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Horometro Nuevo</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="text" id="horometroNuevo" class="form-control form-control-lg"
                                    name="horometroNuevo" required v-model="horometroNuevo"
                                    style="border: 1px #26AF4A  solid;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Mecanico</span>
                            </div>
                            <div class="d-flex justify-content-left">
                               <input type="text" id="mecanico" class="form-control form-control-lg"
                                    name="mecanico" required v-model="mecanico"
                                    style="border: 1px #26AF4A  solid;">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Fecha Mantención</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="date" id="fechaMantencion" class="form-control form-control-lg"
                                    name="fechaMantencion" required v-model="fechaMantencion"
                                    style="border: 1px #26AF4A  solid;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="table-responsive" id="tabla" style="border-radius: 5px;">
                        <table class="table table-striped table-bordered">
                            <thead style="position: sticky;top: 0">
                                <tr>
                                    <th
                                        style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                        Item
                                    </th>
                                    <th
                                        style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                        Cantidad</th>
                                    <th
                                        style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                        Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="index" v-for="(datos, index) in cantidadCompra">
                                    <td>
                                        <div class="d-flex justify-content-center aling-items-center">
                                        <select id="selectItems" class="form-control form-control-lg"
                                            v-model="cantidadCompra[index].item">
                                            <option v-for="opcion in opciones" :key="opcion.id" :value="opcion.id">{{
                                                opcion.nombre }}</option>
                                        </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-center aling-items-center">
                                        <input type="number" id="cantidadItem" class="form-control form-control-lg"
                                            name="emisionFactura" @input="total(index)" required
                                            v-model="cantidadCompra[index].cantidad" min="0" step="1"
                                            style="border: 1px #26AF4A  solid;">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-center aling-items-center">
                                            <button class="btn" @click="eliminarRow(index)" type="button"
                                                style="background-color: red;color: white;">
                                                <b-icon icon="trash" aria-hidden="true"></b-icon>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">

                        <button class="btn" @click="agregarColumna()" type="button"
                            style="background-color: #26AF4A;color: white; width: 100%; font-weight: bold;">
                            Agregar Item
                        </button>
                    </div>
                </div>
                
                
                <div class="container-fluid mb-3">
                    <div class="d-flex justify-content-left">
                        <span>Observación</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <textarea class="form-control" rows="3" v-model="observacion"
                            style=" border:1px #26AF4A solid;"></textarea>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <span>¿Registrar cambio de aceite?  </span><br></div>

                <!-- <div class="d-flex justify-content-center">
                   <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                    <label class="form-check-label" for="flexRadioDefault1">
                    Sí
                    </label>
                    </div>
                    
                </div>
                <div class="d-flex justify-content-center">
                   
                    <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                    <label class="form-check-label" for="flexRadioDefault2">
                       No
                    </label>
                    </div>
                </div><br>-->
                 <div class="d-flex justify-content-center">
                    <div class="row">
                        <div class="col-sm">
                            <select class="form-select" id="cambioAceite" v-model="cambioAceite" >
                                <option value="0" selected>NO</option>
                                <option value="1">SÍ</option>
                            </select>   
                        </div>
                    
                    </div>
                </div><br>
                
                <div class="d-flex justify-content-center">
                    <button type="submit" class="btn" style="background-color: #26AF4A; color: white;font-weight: 600;">
                        Agregar Orden de Trabajo
                    </button>
                </div>
            </form>
        </b-modal>
        
       
        
    </div>
</div>




<div class="d-print-block d-none" style="font-size:14px;">
    <div class="d-flex justify-content-center">
        <div class="row">
            <div class="col-sm">
                <img src="/logoApp.png" style="width:120px">
            </div>
        </div>
    </div>

    <div class="row">
            <div class="col-sm">
                <h5><b>Historial de mantenciones {{patenteBusqueda}} entre {{fecha1Busqueda}} y {{fecha2Busqueda}}</b></h5><br>
            </div>
        </div>
<div>
  
   <table class="table table-striped table-bordered">
                    <thead style="position: sticky;top: 0; font-size:12px;">
                        <tr>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">Patente
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Tipo</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Modelo</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Marca</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Año</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                KM</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                HM</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Observación</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Items</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Fecha</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Mecánico</th>
                           
                        </tr>
                    </thead>
                    <tbody style="font-size:12px">
                        <tr :key="index" v-for="(datos, index) in Mantenciones">
                            <td>{{ datos.patente }}</td>
                            <td>{{ datos.tipo }}</td>
                            <td>{{ datos.modelo }}</td>
                            <td>{{ datos.marca }}</td>
                            <td>{{ datos.año }}</td>
                            <td>{{ datos.kilometraje }}</td>
                            <td>{{ datos.horometro }}</td>
                            <td>{{ datos.observacion }}</td>
                            <td>{{ datos.items }}</td>
                            <td>{{ datos.fechaMantencion}}</td>
                            <td>{{ datos.mecanico}}</td>
                           
                        </tr>
                    </tbody>
                </table>
      
    
    </div>
    
    </div>






</div>
</template>

<script>

import Swal from 'sweetalert2'
//import { format, clean, validate } from 'rut.js'

export default {

    name: 'Inventario',
    components: {

    },
    computed: {

    },
    data: function () {
        return {
            patenteBusqueda:'',
            fecha1Busqueda:'',
            fecha2Busqueda:'',
            patente:'',
            equipo:'',
            kilometrajeActual:'',
            kilometrajeNuevo:'',
            horometroActual:'',
            horometroNuevo:'',
            mecanico:'',
            fechaMantencion:'',
            observacion:'',
            opciones: [],
            Mantenciones: [], stockAgregado: 0, datosEditar: {},
            cantidadCompra: [],
            itemsMantencion:[],
            cambioAceite:'NO',
            urlBd:'https://api.trosales.cl/',
            tk:'',
           usuario:''
           // urlBd: 'http://localhost:3000/',
        }
    },
    methods: {
        imprimir(){
                window.print()
            },


        verItems(id){
            console.log(id)
            this.itemsMantencion=[]
            const url = this.urlBd+'verItemsMantencion/'+id
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
            }
            fetch(url, reqOpciones).then(res => {
                
                res.json().then(data => {
                    console.log(data)
                    if(data.length>0){
                       this.itemsMantencion=data;
                    }else{
                       Swal.fire({
                        icon: 'info',
                        title: 'Patente no encontrada'
                    })
                    }
                   
                })
            })
        },
        async nuevaOrden() {
            this.cantidadCompra = []
            this.agregarColumna()
            this.generarOpciones()
            this.listaTipoPago = [
                { Tipo: 'Contado', valor: 0 },
                { Tipo: 'Débito', valor: 0 },
                { Tipo: 'Crédito', valor: 0 },
                { Tipo: 'Cheque', valor: 0 }
            ]
            this.valorRestante = 0
        },
        async buscarOrdenTrabajo(){
            this.Mantenciones=[]
             document.getElementById("imprimir").style.display="none"
            var patente=document.getElementById("patenteBuscar").value;
            var fecha1=document.getElementById("fecha1").value;
            var fecha2=document.getElementById("fecha2").value;
            const url = this.urlBd+'buscarOrdenTrabajo/'+patente+"/"+fecha1+"/"+fecha2
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
            }
            fetch(url, reqOpciones).then(res => {
                let y=this
                res.json().then(data => {
                    console.log(data)
                    if(data.length>0){
                        document.getElementById("imprimir").style.display="block"
                       data.forEach(function(dato){
                        var x=new Object
                        x.id=dato.id
                        x.patente=dato.patente
                        x.tipo=dato.tipo
                        x.modelo=dato.modelo
                        x.marca=dato.marca
                        x.año=dato.año
                        x.kilometraje=dato.kilometraje
                        x.horometro=dato.horometro
                        x.fechaMantencion=dato.fechaMantencion.split("T")[0]
                        x.observacion=dato.observacion
                        x.mecanico=dato.mecanico
                        x.items=dato.ConcatenatedItems 
                        y.Mantenciones.push(x)
                       })
                    }else{
                       Swal.fire({
                        icon: 'warning',
                        title: 'No se encontraron trabajos'
                    })
                    }
                   
                })
            })
        },



        async buscarPatente(){
            const url = this.urlBd+'buscarPatente/'+this.patente
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
            }
            fetch(url, reqOpciones).then(res => {
                
                res.json().then(data => {
                    console.log(data.message)
                    if(data.message!="error"){
                        this.equipo=data[0].marca+" "+data[0].modelo
                        this.kilometrajeActual=data[0].kilometraje
                        this.horometroActual=data[0].horometro
                    }else{
                       Swal.fire({
                        icon: 'info',
                        title: 'Patente no encontrada'
                    })
                    }
                   
                })
            })
        },

        async agregarOrdenTrabajo() {
            if(this.kilometrajeNuevo>this.kilometrajeActual && this.horometroNuevo>this.horometroActual){
            const datos = {
                patente: this.patente,
                kilometraje:this.kilometrajeNuevo,
                horometro:this.horometroNuevo,
                mecanico: this.mecanico,
                fechaMantencion: this.fechaMantencion,
                observacion:this.observacion,
                lista: this.cantidadCompra,
                alertaAceite:this.cambioAceite,
                usuario:'test'//pasar con la sesion,

            }

            const url = this.urlBd+'registrarOrdenTrabajo'
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(datos)
            }

            fetch(url, reqOpciones).then(res => {
                console.log(res)
               
                //this.recargarEquipos()

                this.patente=''
                this.equipo =''
                this.kilometrajeNuevo=''
                this.horometroNuevo=''
                this.mecanico=''
                this.fechaMantencion=''
                this.observacion=''
                this.cantidadCompra=''
                this.cambioAceite='NO'
                

                res.json().then(data => {

                    this.$bvModal.hide('modal-AgregarOrden')

                    Swal.fire({
                        icon: 'success',
                        title: 'Trabajo Registrado'
                    })

                    console.log(data)
                })
            })
            }else{
               Swal.fire({
                        icon: 'info',
                        title: 'Kilometraje y horometro deben ser mayor que el actual'
                    })     
            }
        },
        
        
        
        
       
        async pasoDatos(Datos) {

            this.datosEditar = Datos
            this.nombre = Datos.nombre
            this.descripcion = Datos.descripcion
            this.stock = Datos.stock
            this.stockMinimo = Datos.stockMinimo
            this.auditado = false
            this.fechaInicial = null
            this.fechaFinal = null
            this.movimientosItem = []
            this.stockAgregado = null
            this.hayCambios = false
            if (Datos.activo == true) {
                this.itemActivo = true
            } else {
                this.itemActivo = false
            }
        },
      
        
        async agregarColumna() {
            const objetoNuevo = {
                item: null,
                cantidad: 1,
                valorU: 0,
                valorT: 0
            }
            this.cantidadCompra.push(objetoNuevo)
        },
       
        async generarOpciones() {
            console.log("opciones")
            var Items = this.Items
            this.opciones = []
            Items.sort((a, b) => {
                const nombreA = a.nombre.toLowerCase(); // Convertir a minúsculas para ordenar sin importar mayúsculas/minúsculas
                const nombreB = b.nombre.toLowerCase();

                if (nombreA < nombreB) {
                    return -1; // a debe venir antes que b en el orden
                }
                if (nombreA > nombreB) {
                    return 1; // a debe venir después que b en el orden
                }
                return 0; // a y b son iguales en términos de ordenación
            });
            Items.forEach(elem => {
                var opcion = {
                    id: elem.id,
                    nombre: elem.nombre
                }
                this.opciones.push(opcion)
            })
        },
        eliminarRow(index) {
            this.cantidadCompra.splice(index, 1);
        },
        async total(index) {
            const cantidad = this.cantidadCompra[index].cantidad
            const valorU = this.cantidadCompra[index].valorU
            const total = cantidad * valorU
            this.cantidadCompra[index].valorT = total
            this.modificarValoresFinal()
        },
        async modificarValoresFinal() {
            const lista = this.cantidadCompra
            var suma = 0
            lista.forEach(elem => {
                suma += parseInt(elem.valorT)
            })
            const iva = parseInt(Math.round(suma * 0.19))
            const total = Math.round(suma + iva)
            this.valorNeto = suma
            this.IVA = iva
            this.valorTotal = total
            this.valorRestante = parseInt(total)
        },
        async restarTotal() {
            var restante = parseInt(this.valorTotal)
            const lista = this.listaTipoPago
            lista.forEach(ele => {
                if (ele.valor === '') {
                    ele.valor = 0
                }
                restante = restante - parseInt(ele.valor)
            })
            this.valorRestante = restante
        }
    },
    mounted() {
         var token = localStorage.getItem("token");
          var tipo = localStorage.getItem("tipo");
           this.tk=localStorage.getItem("token");
           this.usuario=localStorage.getItem("usuario");
         // console.log(token,tipo); 
          if(!token || tipo!=0){
            this.$router.push('/');
          }
       const direccionApi = 'https://trosales.williamsmejias.cl/'
        // const direccionApi = 'http://localhost:3000/'
        this.direccionApi = direccionApi

        const url = this.direccionApi + 'items'
        const reqOpciones = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                    'Authorization':this.tk,
            }
        }

        fetch(url, reqOpciones)
            .then(res => {
                if (!res) {
                    console.log(`Error: ${res.status}`)
                } else {
                    res.json().then(data => {
                        this.Items = data
                    })
                }
            })
    }
}
</script>

<style>
h1 {
    color: #26AF4A;
    font-weight: bold;
    font-size: xx-large;
}

h2 {
    color: #26AF4A;
    font-weight: bold;
    font-size: x-large;
}

span {
    color: #26AF4A;
    font-size: large;
    font-weight: 500;
}

.form-check-input:checked {
    background-color: #26AF4A;
    border-color: #26AF4A;
}

.container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 40px;
    margin: auto;
    text-align: center;
}

/** Switch
 -------------------------------------*/

.switch input {
    position: absolute;
    opacity: 0;
}

/**
 * 1. Adjust this to size
 */

.switch {
    display: inline-block;
    font-size: 20px;
    /* 1 */
    height: 1em;
    width: 2em;
    background: #BDB9A6;
    border-radius: 1em;
    margin-left: 1%;
    margin-right: 1%;
}

.switch div {
    height: 1em;
    width: 1em;
    border-radius: 1em;
    background: #FFF;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
}

.switch input:checked+div {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}
</style>