<template>
    <div class="content">
        <nav class="navbar navbar-expand-lg navbar-light shadow d-print-none" style="background-color: rgb(255,255,255);">
            <a class="navbar-brand">
                <img width="150px" style="margin-left: 1%;" class="img-fluid" src="../assets/logo.jpeg">

            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
                style="color: white;background-color: white; margin-right: 1%;">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown" style="font-size: large;">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" href="/alertas"><b-icon style=" color:#27AE4A" icon="house"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Alertas</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/trabajos"><b-icon style=" color:#27AE4A" icon="tools"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Trabajos</span></a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="/equipos"><b-icon style=" color:#27AE4A" icon="truck"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A;"> Equipos</span></a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="/inventario"><b-icon style=" color:#27AE4A" icon="box-seam"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Inventario</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/proyectos"><b-icon style=" color:#27AE4A" icon="briefcase"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Proyectos</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/usuarios"><b-icon style=" color:#27AE4A" icon="person"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Usuarios</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/cotizaciones"><b-icon style=" color:#27AE4A" icon="file-ruled"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Cotizaciones</span></a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="container-fluid col-md-11 shadow mt-3" style="background-color: white; border-radius: 10px;">
            <div class="d-flex justify-content-center">
                <h1 style="color:#26AF4A;font-weight: bold;">Usuarios</h1>
            </div>
            <div class="d-flex justify-content-center mb-2">
                <div class="col-md-1">
                    <select id="selectItems" class="form-control" v-model="TipoBusqueda" @change="filtrar()"
                        style="border: 1px #26AF4A solid;background-color: #26AF4A;color:white;font-weight: bold;border-top-right-radius: 0;border-bottom-right-radius: 0;">
                        <option selected value="0">Filtrar ▼</option>
                        <option value="1">Nombre</option>
                        <option value="2">Rut</option>
                    </select>
                </div>
                <div class="col-md-4">
                <input type="text" class="form-control" aria-label="Username" v-model="ValorBusqueda" @input="filtrar()"
                            aria-describedby="basic-addon1" style="border: 1px #26AF4A solid; border-bottom-left-radius: 0;border-top-left-radius: 0;">
                </div>
            </div>
            <div class="table-responsive" id="tabla">
                <table class="table table-striped table-bordered">
                    <thead style="position: sticky;top: 0">
                        <tr>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Nombre
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Rut
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Teléfono</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Correo</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Tipo</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Editar</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Clave</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Usuarios">
                            <td>{{ datos.nombre }}</td>
                            <td>{{ datos.rut }}</td>
                            <td>{{ datos.telefono }}</td>
                            <td>{{ datos.correo }}</td>
                            <td>
                                <div v-if="datos.tipo === 0">Administrador</div>
                                <div v-if="datos.tipo === 1">Chofer</div>
                                <div v-if="datos.tipo === 2">Mecánico</div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center aling-items-center">
                                    <button v-b-modal.modal-Editar class="btn" @click="pasoDatos(datos.rut,datos.nombre,datos.telefono,datos.correo,datos.tipo)"
                                        style="background-color: #26AF4A;color: white;">
                                        <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>
                             <td> 
                        <button type="button" class="btn btn-secondary btn-sm"  
                        @click="cambiarClave(datos.rut)">
                            <i class="fas fa-lock fa-2x"></i>
                        </button>
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="container-fluid col-md-12">
                <button class="btn  mb-1" v-b-modal.modal-AgregarUsuario type="button"
                    style="background-color: #26AF4A;color: white;width: 100%; font-weight: bold;">
                    Agregar Usuario
                </button>
            </div>
        </div>
        <b-modal id="modal-AgregarUsuario" size="xl" hide-footer hide-header>
            <div class="d-flex justify-content-center">
                <h2 style="color:#26AF4A; font-weight: bold;">Agregar Usuario</h2>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span>Rut</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <input type="text" id="Rut" class="form-control form-control-lg" name="Rut" required maxlength="10"
                            @input="formatoRUT" v-model="Rut" style="border: 1px #26AF4A  solid;">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span>Nombre</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <input type="text" id="Nombre" class="form-control form-control-lg" name="Nombre" required
                            v-model="Nombre" style="border: 1px #26AF4A  solid;">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span>Teléfono</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon1"
                                style="border: 1px #26AF4A solid; ;color:white;font-weight: bold; background-color: #26AF4A;">+56</span>
                            <input type="text" class="form-control form-control-lg" aria-label="Username" maxlength="9"
                                required aria-describedby="basic-addon1" v-model="Telefono"
                                style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span>Correo</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <input type="text" id="Correo" class="form-control form-control-lg" name="Correo" v-model="Correo"
                            required style="border: 1px #26AF4A  solid;">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-2">
                    <div class="d-flex justify-content-left">
                        <span>Contraseña</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <input type="text" id="contraseñaNueva" class="form-control form-control-lg" name="contraseñaNueva" required
                            v-model="Pass" style="border: 1px #26AF4A  solid;">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span>Tipo de Usuario</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <select id="selectItems" class="form-control form-control-lg" v-model="TipoUsuario"
                            style="border: 1px #26AF4A solid">
                            <option selected hidden value="no">Seleccione un tipo ▼</option>
                            <option value="0">Administrador</option>
                            <option value="1">Chofer</option>
                            <option value="2">Mecánico</option>
                        </select>
                    </div>
                </div>
            </div>
            <button class="btn  mb-1" v-b-modal.modal-AgregarUsuario type="button" @click="RegistrarUsuario()"
                style="background-color: #26AF4A;color: white;width: 100%; font-weight: bold;">
                Registrar Usuario
            </button>
        </b-modal>
        <b-modal id="modal-Editar" size="xl" hide-footer hide-header>
            <div class="d-flex justify-content-center">
                <h2 style="color:#26AF4A; font-weight: bold;">Editar Usuario</h2>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span>Rut</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <input type="text" id="rutEditar" class="form-control form-control-lg" name="rutEditar" required maxlength="10"
                            @input="formatoRUT" v-model="rutEditar" style="border: 1px #26AF4A  solid;" readonly>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span>Nombre</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <input type="text" id="nombreEditar" class="form-control form-control-lg" name="nombreEditar" required
                            v-model="nombreEditar" style="border: 1px #26AF4A  solid;">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span>Teléfono</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon1"
                                style="border: 1px #26AF4A solid; ;color:white;font-weight: bold; background-color: #26AF4A;">+56</span>
                            <input type="text" class="form-control form-control-lg" aria-label="Username" maxlength="9"
                                required aria-describedby="basic-addon1" v-model="telefonoEditar"
                                style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span>Correo</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <input type="text" id="correoEditar" class="form-control form-control-lg" name="correoEditar" v-model="correoEditar"
                            required style="border: 1px #26AF4A  solid;">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span>Tipo de Usuario</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <select id="selectItems" class="form-control form-control-lg" v-model="tipoUsuarioEditar"
                            style="border: 1px #26AF4A solid">
                            <option selected hidden value="no">Seleccione un tipo ▼</option>
                            <option value="0">Administrador</option>
                            <option value="1">Chofer</option>
                            <option value="2">Mecánico</option>
                        </select>
                    </div>
                </div>
            </div><br>
            <button class="btn  mb-1"  type="button" @click="EditarUsuario()"
                style="background-color: #26AF4A;color: white;width: 100%; font-weight: bold;">
                Editar Usuario
            </button>
        </b-modal>
        
  <b-modal id="modal-clave"  size="xl" hide-footer hide-header >
    
    <div class="d-block text-center">
      <h3  style="color:#26AF4A;font-weight: bold;"><b>Restablecer Clave</b></h3><br>
           
                <div class="row">
                    <div class="col-sm-6">
                       <div class="form-group">
                        <label  style="color:#26AF4A;font-weight: bold;">Nueva Clave</label>
                        <input type="text" id="clave1"  v-model="clave1" class="form-control" placeholder="Ingrese nueva clave"  style="border-color: #26AF4A"  required>
                        </div>
                    </div>
                    <div class="col-sm-6">
                       <div class="form-group">
                        <label  style="color:#26AF4A;font-weight: bold;">Confirme Nueva Clave</label>
                        <input type="text" id="clave2"  v-model="clave2" class="form-control" placeholder="Confirme nueva clave" style="border-color: #26AF4A"  required>
                        </div>
                    </div>
                </div><br>
               
               

               
                <div class="d-flex justify-content-center">
                    <div class="form-group">
                        <button class="btn btn-success btn-block" @click="cambiarClave2()" style="background-color: #26AF4A;color: white;width: 100%; font-weight: bold" >Cambiar</button><br><br>
                    </div>
                </div>

    </div>

  </b-modal> 

    </div>
</template>

<script>
 const bcrypt = require('bcryptjs');
import { clean, format, validate } from 'rut.js'
import Swal from 'sweetalert2';

export default {

    name: 'Inventario',
    components: {

    },
    computed: {

    },
    data: function () {
        return {
            Usuarios: [], AuxUsuarios:[],Rut: null, Nombre: null, Telefono: null,
            Correo: null, Pass: null, TipoUsuario: 'no',Datos:[],
            direccionApi: null, TipoBusqueda: '0', ValorBusqueda: null,
            rutEditar:'',nombreEditar:'',telefonoEditar:'',correoEditar:'',tipoUsuarioEditar:'',
            tk:'',
           usuario:'',clave1:'',clave2:''
        }
    },
    methods: {
        async obtenerUsuarios() {
           this.Usuarios=[];
           this.AuxUsuarios=[];
               // let y=this
                fetch(this.direccionApi+'usuarios', {
                headers: {
                    'Content-Type': 'application/json' ,
                    'Authorization':this.tk,
                },
                //mode: 'no-cors'
                })
                .then(response => response.json())
                .then(data => {
                   // console.log(data.length)
                    if(data.length>0){
                        this.Usuarios=data
                        this.AuxUsuarios=data
                    }else{
                         Swal.fire({
                            title: "No se han encontrado usuarios",
                            icon: "error",
                        })
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },
        async validarEmail(valor) {
            /*
                     verifica que el formato del correo sea correcto,
                      o sea, que contenga un '@' y '.algo'
                  */
            if (
                /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                    valor
                )
            ) {
                return true;
            } else {
                return false;
            }
        },
        async formatoRUT() {
            const rut = clean(this.Rut)
            const rutFormateado = format(rut, { dots: false })
            this.Rut = rutFormateado
        },
        RegistrarUsuario(){
                const plaintextPassword = this.Pass.toString(); // Tu contraseña en texto plano
                const saltRounds = 12; // Número de rondas de sal (recomendado entre 10 y 12)
                let y=this
                // Genera una sal aleatoria
                bcrypt.genSalt(saltRounds, function(err, salt) {
                if (err) {
                    // Handle error
                    console.error(err);
                } else {
                    // Hashea la contraseña con la sal generada
                    bcrypt.hash(plaintextPassword, salt, function(err, hash) {
                    if (err) {
                        // Handle error
                        console.error(err);
                    } else {
                        // El hash de la contraseña está en la variable 'hash'
                       // console.log('Contraseña hasheada:', hash);
                        if(y.Rut!="" && y.Nombre!="" && y.Pass!="" && y.TipoUsuario!=""){
                    var x = {
                        rut: y.Rut,
                        nombre: y.Nombre.toUpperCase(),
                        tipo:y.TipoUsuario,
                        password:plaintextPassword,
                        correo: y.Correo,
                        telefono: y.Telefono,
                        sk:hash
                    };

                fetch(y.direccionApi+'registrarUsuario', {
                        method: 'post',
                        headers: {
                               //'Authorization':y.tk,
                               'Content-Type': 'application/json' ,
                                'Authorization':y.tk,// Establece el tipo de contenido como JSON
                        },
                        body: JSON.stringify(x) 
                
                })
                .then(response => response.json())
                .then(data => {
                    
                    console.log(data)
                    y.Nombre = null
                    y.Rut = null
                    y.Telefono = null
                    y.Correo = null
                    y.Pass = null
                    y.TipoUsuario = 'no'
                    y.$bvModal.hide('modal-AgregarUsuario')
                    Swal.fire({
                        icon: 'success',
                        title: 'Registrado con éxito'
                    })
                    y.obtenerUsuarios()
                   
                })
                .catch(error => {
                    console.error('Error:', error);
                });



                }else{
                    console.log("faltan datos")
                }
                    }
                    });
                }
                });
               
                
            },
        async RegistrarUsuariox() {
            if (this.TipoUsuario === 'no') {
                Swal.fire({
                    icon: 'warning',
                    title: 'Seleccione tipo de usuario'
                })
                return
            }
            if (!validate(this.Rut)) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Rut Inválido',
                    text: 'Revise Rut ingresado y vuelva a intentar'
                })
                return
            }
            const rutValidado = await this.validarEmail(this.Correo)
            if (!rutValidado) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Correo no válido',
                    text: 'Correo ingresado no tiene formato válido'
                })
                return
            }
            const datos = {
                rut: this.Rut,
                nombre: this.Nombre.toUpperCase(),
                tipoUsuario: this.TipoUsuario,
                pass: this.Pass,
                correo: this.Correo.toUpperCase(),
                telefono: this.telefono
            }

            const url = this.direccionApi + 'registrarUsuario'

            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(datos)
            }

            fetch(url, reqOpciones).then(res => {
                if (!res.ok) {
                    console.log(`Error: ${res.status}`)
                } else {

                    this.Nombre = null
                    this.Rut = null
                    this.Telefono = null
                    this.Correo = null
                    this.Pass = null
                    this.TipoUsuario = 'no'
                    this.$bvModal.hide('modal-AgregarUsuario')
                    this.obtenerUsuarios()
                    Swal.fire({
                        icon: 'success',
                        title: 'Registrado con éxito'
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        },
        async EditarUsuario() {
            if (this.tipoUsuarioEditar === 'no') {
                Swal.fire({
                    icon: 'warning',
                    title: 'Seleccione tipo de usuario'
                })
                return
            }
            const rutValidado = await this.validarEmail(this.correoEditar)
            if (!rutValidado) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Correo no válido',
                    text: 'Correo ingresado no tiene formato válido'
                })
                return
            }
            var x = {
                        rut: this.rutEditar,
                        nombre: this.nombreEditar.toUpperCase(),
                        tipo:this.tipoUsuarioEditar,
                        correo: this.correoEditar.toUpperCase(),
                        telefono: this.telefonoEditar
                    };
            
            console.log(x)
            const url = this.direccionApi + 'editarUsuario'

            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(x)
            }

            fetch(url, reqOpciones).then(res => {
                if (!res.ok) {
                    console.log(`Error: ${res.status}`)
                } else {

                     this.rutEditar=""
                    this.nombreEditar=""
                    this.tipoUsuarioEditar="no"
                    this.correoEditar=""
                    this.telefonoEditar=""
                    this.$bvModal.hide('modal-Editar')
                    this.obtenerUsuarios()
                    Swal.fire({
                        icon: 'success',
                        title: 'Actualizado con éxito'
                    })
                }
            }).catch(err => {
                console.log(err)
               
            })
        },
        async pasoDatos(rut,nombre,telefono,correo,tipo) {
            this.rutEditar=rut
            this.nombreEditar=nombre
            this.tipoUsuarioEditar=tipo
            this.correoEditar=correo
            this.telefonoEditar=telefono
        },
            leer2(){
                var rut = this.rut.replaceAll(".","");
				var rutx=rut.replace("-","");
				var largo=rutx.length
				//console.log(largo,largo-1)
				var rut2=rutx.slice(0,largo-1)
				var rut3=rutx.slice(largo-1)
                //console.log(rut2+"-"+rut3)
				this.rut=(rut2+"-"+rut3)
				
            },
        async filtrar(){
            const tipoFiltro = this.TipoBusqueda
            const ValorBusqueda = this.ValorBusqueda.toUpperCase()
            var lista = this.AuxUsuarios
            if(tipoFiltro == 1){
                lista = lista.filter(objeto => {
                    if(ValorBusqueda){
                        return objeto.nombre.includes(ValorBusqueda)
                    }

                    return true
                })
            }else if(tipoFiltro == 2){
                lista = lista.filter(objeto => {
                    if(ValorBusqueda){
                        return objeto.rut.includes(ValorBusqueda)
                    }
                    return true
                })
            }

            this.Usuarios = lista
        },
         cambiarClave(rut){
                this.$bvModal.show("modal-clave")
                this.rutClave=rut
            },

            cambiarClave2(){
                if(this.clave1!="" && this.clave1==this.clave2){
                const plaintextPassword = this.clave1; // Tu contraseña en texto plano
                const saltRounds = 12; // Número de rondas de sal (recomendado entre 10 y 12)
                let y=this
                // Genera una sal aleatoria
                bcrypt.genSalt(saltRounds, function(err, salt) {
                if (err) {
                    // Handle error
                    console.error(err);
                } else {
                    // Hashea la contraseña con la sal generada
                    bcrypt.hash(plaintextPassword, salt, function(err, hash) {
                    if (err) {
                        // Handle error
                        console.error(err);
                    } else {
                        // El hash de la contraseña está en la variable 'hash'
                       // console.log('Contraseña hasheada:', hash);
                       
                    var x = {
                        rut: y.rutClave,
                        password:y.clave1,
                        sk:hash
                    };
                console.log(x)
                fetch(y.direccionApi+'cambiarClaveUsuario', {
                        method: 'post',
                        headers: {
                               'Authorization':y.tk,
                               'Content-Type': 'application/json' // Establece el tipo de contenido como JSON
                        },
                        body: JSON.stringify(x) 
                
                })
                .then(response => response.json())
                .then(data => {
                    
                    console.log(data)
                    y.$bvModal.hide("modal-clave")
                     Swal.fire({
                            title: "Listo",
                            text: "Clave Actualizada",
                            icon: "success",
                        })
                        y.rutClave=""
                        y.clave1=""
                        y.clave2=""
                  
                   
                })
                .catch(error => {
                    console.error('Error:', error);
                });



               
                    }
                    });
                }
                });
                }else{
                    Swal.fire({
                        title: "Error",
                        text: "Las claves deben ser iguales",
                        icon: "error",
                    })
                }
                
               
                
            },  
    },
    mounted() {
         var token = localStorage.getItem("token");
          var tipo = localStorage.getItem("tipo");
           this.tk=localStorage.getItem("token");
           this.usuario=localStorage.getItem("usuario");
         // console.log(token,tipo); 
          if(!token || tipo!=0){
            this.$router.push('/');
          }
        const direccionApi = 'https://api.trosales.cl/'
        //const direccionApi = 'http://localhost:3000/'
        this.direccionApi = direccionApi

        this.obtenerUsuarios()

    }
}
</script>

<style>
h1 {
    color: #26AF4A;
    font-weight: bold;
    font-size: xx-large;
}

h2 {
    color: #26AF4A;
    font-weight: bold;
    font-size: x-large;
}

span {
    color: #26AF4A;
    font-size: large;
    font-weight: 500;
}

.form-check-input:checked {
    background-color: #26AF4A;
    border-color: #26AF4A;
}

.container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 40px;
    margin: auto;
    text-align: center;
}

/** Switch
 -------------------------------------*/

.switch input {
    position: absolute;
    opacity: 0;
}

/**
 * 1. Adjust this to size
 */

.switch {
    display: inline-block;
    font-size: 20px;
    /* 1 */
    height: 1em;
    width: 2em;
    background: #BDB9A6;
    border-radius: 1em;
    margin-left: 1%;
    margin-right: 1%;
}

.switch div {
    height: 1em;
    width: 1em;
    border-radius: 1em;
    background: #FFF;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
}

.switch input:checked+div {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}


/* Define una clase personalizada para el cuadro de entrada de contraseña */
.custom-input-class {
    z-index: 9999;
    /* Establece un índice z alto para asegurarse de que esté por encima de otras capas, como modales */
}
</style>