
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA46U98sJboJUgIfWODnLWQ7fZfEwRe5P4",
    authDomain: "testtrosales.firebaseapp.com",
    projectId: "testtrosales",
    storageBucket: "testtrosales.appspot.com",
    messagingSenderId: "373972400427",
    appId: "1:373972400427:web:0a7d129ee0832f7bebad6e"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);
//const storage=firebase.storage();
export const db = firebaseApp.firestore();
export const  storage=firebase.storage();
/*export {
    storage    
}*/