<template>
    <div class="content" style="background-color: #e7e7e7;">
        <nav class="navbar navbar-expand-lg navbar-light shadow d-print-none" style="background-color: rgb(255,255,255);">
            <a class="navbar-brand">
                <img width="150px" style="margin-left: 1%;" class="img-fluid" src="../assets/logo.jpeg">

            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
                style="color: white;background-color: white; margin-right: 1%;">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown" style="font-size: large;">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" href="/alertas"><b-icon style=" color:#27AE4A" icon="house"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Alertas</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/trabajos"><b-icon style=" color:#27AE4A" icon="tools"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Trabajos</span></a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="/equipos"><b-icon style=" color:#27AE4A" icon="truck"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A;"> Equipos</span></a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="/inventario"><b-icon style=" color:#27AE4A" icon="box-seam"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Inventario</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/proyectos"><b-icon style=" color:#27AE4A" icon="briefcase"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Proyectos</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/usuarios"><b-icon style=" color:#27AE4A" icon="person"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Usuarios</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/cotizaciones"><b-icon style=" color:#27AE4A" icon="file-ruled"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Cotizaciones</span></a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="container-fluid col-md-11 shadow mt-3" style="background-color: white; border-radius: 10px;">
            <div class="d-flex justify-content-center">
                <h1 style="color:#26AF4A;font-weight: bold;">Proyectos</h1>
            </div>
            <div class="d-flex justify-content-left">
                <h6 style="color:#26AF4A;font-weight: bold;">Filtros:</h6>
            </div>
            <div class="row mb-2">
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-4">
                            <div class="d-flex justify-content-center align-items-center">

                                <h6 style="color:#26AF4A;font-size: medium;">Cliente:</h6>
                            </div>
                        </div>
                        <div class="col-8">
                            <input @input="filtro();" type="text" class="form-control form-control-sm" v-model="Cliente"
                                style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-4">
                            <div class="d-flex justify-content-center align-items-center">
                                <h6 style="color:#26AF4A;font-size: medium;">Estado:</h6>
                            </div>
                        </div>
                        <div class="col-8">
                            <select @change="filtro()" class="form-control form-control-sm" id="estadoFiltro"
                                style="border: 1px #26AF4A  solid;" v-model="estado">
                                <option selected value="3">Todos</option>
                                <option value="0">En Progreso</option>
                                <option value="1">Finalizados</option>
                                <option value="2">Cancelados</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-4">
                            <div class="d-flex justify-content-center align-items-center">

                                <h6 style="color:#26AF4A;font-size: medium;">Sector:</h6>
                            </div>
                        </div>
                        <div class="col-8">
                            <input @input="filtro();" type="text" class="form-control form-control-sm" v-model="Sector"
                                style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive" id="tabla">
                <table class="table table-striped table-bordered">
                    <thead style="position: sticky;top: 0">
                        <tr>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Cliente
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Sector</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Estado</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Fecha Inicio</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Fecha Limite</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Fecha Termino</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Turnos</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Editar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Proyectos">
                            <td>{{ datos.cliente }}</td>
                            <td>{{ datos.sector }}</td>
                            <td>
                                <div v-if="datos.estado === 0" class="d-flex justify-content-center aling-items-center">
                                    <span style="color: #60b3f3;">
                                        <b-icon icon="clock" aria-hidden="true"></b-icon>
                                    </span>
                                </div>
                                <div v-if="datos.estado === 1" class="d-flex justify-content-center aling-items-center">
                                    <span style="color: #26AF4A;">
                                        <b-icon icon="check-lg" aria-hidden="true"></b-icon>
                                    </span>
                                </div>
                                <div v-if="datos.estado === 2" class="d-flex justify-content-center aling-items-center">
                                    <span style="color: red;">
                                        <b-icon icon="x-lg" aria-hidden="true"></b-icon>
                                    </span>
                                </div>
                            </td>
                            <td>{{ datos.fechaInicio }}</td>
                            <td>{{ datos.fechaLimite }}</td>
                            <td>{{ datos.fechaTermino }}</td>
                            <td>
                                <div class="d-flex justify-content-center aling-items-center">
                                    <button v-b-modal.modal-turnos @click="buscarTurnos(datos)" class="btn"
                                        style="background-color: #26AF4A; color: white;">
                                        <b-icon icon="file-earmark-person" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center aling-items-center">
                                    <button v-b-modal.modal-Editar @click="obtenerDatos(datos)" class="btn"
                                        style="background-color: #26AF4A; color: white;">
                                        <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="container-fluid col-md-12">
                <button class="btn  mb-1" v-b-modal.modal-AgregarProyecto type="button" @click="buscarChoferes()"
                    style="background-color: #26AF4A;color: white;width: 100%; font-weight: bold;">
                    Agregar Proyecto
                </button>
            </div>
        </div>
        <b-modal id="modal-turnos" size="md" hide-footer>
            <div class="d-flex justify-content-center">
                <h2 style="color:#26AF4A; font-weight: bold;">Turnos de Proyecto: {{ datosEditar.cliente }}</h2>
            </div>
            <div class="table-responsive mb-2" id="tablaTurnos">
                <table class="table table-striped table-bordered">
                    <thead style="position: sticky;top: 0">
                        <tr>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Turno
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Rut
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Nombre
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Equipo
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(dato, index) in Turnos">
                            <td>{{ dato.turno }}</td>
                            <td>{{ dato.nombre }}</td>
                            <td>{{ dato.rut }}</td>
                            <td>{{ dato.idEquipo }}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>

        <b-modal id="modal-AgregarProyecto" size="xl" hide-footer hide-header>
            <form @submit.prevent="agregarProyecto()">
                <div class="d-flex justify-content-center">
                    <h2 style="color:#26AF4A; font-weight: bold;">Agregar Proyecto</h2>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span>Cliente</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="cliente" class="form-control form-control-lg" name="cliente" required
                                v-model="cliente" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span>Sector</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="zona" class="form-control form-control-lg" name="zona" required
                                v-model="sector" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span>Fecha Inicio</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="date" id="cliente" class="form-control form-control-lg" name="cliente" required
                                v-model="fechaInicio" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span>Fecha Límite</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="date" id="zona" class="form-control form-control-lg" name="zona" required
                                v-model="fechaLimite" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-left">
                    <span>Turnos:</span>
                </div>
                <div class="table-responsive" id="tablaEquiposAgregar">
                    <table class="table table-striped table-bordered">
                        <thead style="position: sticky;top: 0">
                            <tr>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Rut</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Nombre</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Turno</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Equipo</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="index" v-for="(datos, index) in ChoferesAgregar">
                                <td>{{ datos.rut }}</td>
                                <td>{{ datos.nombre }}</td>
                                <td>
                                    <div class="d-flex justify-content-center">
                                        <select id="selectTurno" class="form-control" v-model="ChoferesAgregar[index].turno"
                                            style="border: 1px #26AF4A  solid;">
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="datos.idEquipo" class="d-flex justify-content-center">
                                        {{ datos.idEquipo }}
                                    </div>
                                    <div v-else class="d-flex justify-content-center">
                                        <button class="btn" type="button" @click="buscarEquipos(); chofer = index"
                                            v-b-modal.modal-AgregarEquipo style="background-color: #26AF4A;color: white">
                                            <b-icon icon="plus-lg" aria-hidden="true"></b-icon>
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex justify-content-center">
                                        <button class="btn" type="button" @click="quitarChofer(index, datos)"
                                            style="background-color: red;color: white">
                                            <b-icon icon="trash" aria-hidden="true"></b-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex justify-content-center">
                    <button class="btn" type="button" v-b-modal.modal-AgregarChofer
                        style="background-color: #26AF4A;color: white;  font-weight: bold;">
                        Agregar Chofer
                    </button>
                </div>
                <div class="d-flex justify-content-left">
                    <span>Observacion</span>
                </div>
                <div class="d-flex justify-content-left">
                    <textarea class="form-control mb-2" rows="2" v-model="observacion"
                        style=" border:1px #26AF4A solid;"></textarea>
                </div>
                <div class="d-flex justify-content-center">
                    <button class="btn" type="submit"
                        style="background-color: #26AF4A;color: white; width: 100%; font-weight: bold;">
                        Agregar Proyecto
                    </button>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-AgregarEquipo" size="md" hide-footer>
            <div class="d-flex justify-content-center">
                <h2 style="color:#26AF4A; font-weight: bold;">Agregar Equipo</h2>
            </div>
            <div class="row mb-2">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-4">
                            <div class="d-flex justify-content-center align-items-center">

                                <h6 style="color:#26AF4A;font-size: medium;">Patente:</h6>
                            </div>
                        </div>
                        <div class="col-8">
                            <input @input="filtroEquipos();" type="text" class="form-control form-control-sm"
                                v-model="patente" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">

                    <div class="row">
                        <div class="col-4">
                            <div class="d-flex justify-content-center align-items-center">

                                <h6 style="color:#26AF4A;font-size: medium;">Marca:</h6>
                            </div>
                        </div>
                        <div class="col-8">
                            <input @input="filtroEquipos();" type="text" class="form-control form-control-sm"
                                v-model="marca" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive" id="tablaChoferes">
                <table class="table table-striped table-bordered">
                    <thead style="position: sticky;top: 0">
                        <tr>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Patente</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Marca</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Agregar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Equipos">
                            <td>{{ datos.patente }}</td>
                            <td>{{ datos.marca }}</td>
                            <td>
                                <div class="d-flex justify-content-center">
                                    <button class="btn mb-2" type="button" @click="agregarEquipo(datos)"
                                        style="background-color: #26AF4A;color: white;">
                                        <b-icon icon="plus-lg" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <b-modal id="modal-AgregarChofer" size="md" hide-footer>
            <div class="d-flex justify-content-center">
                <h2 style="color:#26AF4A; font-weight: bold;">Agregar Chofer</h2>
            </div>
            <div class="row mb-2">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-4">
                            <div class="d-flex justify-content-center align-items-center">

                                <h6 style="color:#26AF4A;font-size: medium;">RUT:</h6>
                            </div>
                        </div>
                        <div class="col-8">
                            <input @input="filtroChoferes();" type="text" class="form-control form-control-sm"
                                v-model="patente" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="col-md-6">

                    <div class="row">
                        <div class="col-4">
                            <div class="d-flex justify-content-center align-items-center">

                                <h6 style="color:#26AF4A;font-size: medium;">Nombre:</h6>
                            </div>
                        </div>
                        <div class="col-8">
                            <input @input="filtroChoferes();" type="text" class="form-control form-control-sm"
                                v-model="marca" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive" id="tablaChoferes">
                <table class="table table-striped table-bordered">
                    <thead style="position: sticky;top: 0">
                        <tr>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Rut</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Nombre</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Agregar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Choferes">
                            <td>{{ datos.rut }}</td>
                            <td>{{ datos.nombre }}</td>
                            <td>
                                <div v-if="!datos.usado" class="d-flex justify-content-center">
                                    <button class="btn mb-2" type="button" @click="agregarChofer(datos)"
                                        style="background-color: #26AF4A;color: white;">
                                        <b-icon icon="plus-lg" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                                <div v-else class="d-flex justify-content-center">
                                    <button disabled class="btn mb-2" type="button"
                                        style="background-color: #26AF4A;color: white;">
                                        <b-icon icon="plus-lg" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <b-modal id="modal-Editar" size="xl" hide-footer hide-header>
            <div class="d-flex justify-content-center">
                <h2 style="color:#26AF4A; font-weight: bold;">Editar</h2>
            </div>
            <form @submit.prevent="Editar()">
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span>Cliente</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="cliente" class="form-control form-control-lg" name="cliente" required
                                v-model="datosEditar.cliente" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span>Sector</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="zona" class="form-control form-control-lg" name="zona" required
                                v-model="datosEditar.sector" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span>Fecha Inicio</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="date" id="cliente" class="form-control form-control-lg" name="cliente" required
                                v-model="datosEditar.fechaInicio" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span>Fecha Límite</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="date" id="zona" class="form-control form-control-lg" name="zona" required
                                v-model="datosEditar.fechaLimite" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-left">
                    <span>Turnos:</span>
                </div>
                <div class="table-responsive" id="tablaEquiposAgregar">
                    <table class="table table-striped table-bordered">
                        <thead style="position: sticky;top: 0">
                            <tr>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Rut</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Nombre</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Turno</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Equipo</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Borrar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="index" v-for="(datos, index) in ChoferesAgregar">
                                <td>{{ datos.rut }}</td>
                                <td>{{ datos.nombre }}</td>
                                <td>
                                    <div class="d-flex justify-content-center">
                                        <select id="selectTurno" class="form-control" v-model="ChoferesAgregar[index].turno"
                                            style="border: 1px #26AF4A  solid;">
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="datos.idEquipo" class="d-flex justify-content-center">
                                        {{ datos.idEquipo }}
                                    </div>
                                    <div v-else class="d-flex justify-content-center">
                                        <button class="btn" type="button" @click="buscarEquipos(); chofer = index"
                                            v-b-modal.modal-AgregarEquipo style="background-color: #26AF4A;color: white">
                                            <b-icon icon="plus-lg" aria-hidden="true"></b-icon>
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex justify-content-center">
                                        <button class="btn" type="button" @click="quitarChofer(index, datos)"
                                            style="background-color: red;color: white">
                                            <b-icon icon="trash" aria-hidden="true"></b-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex justify-content-center">
                    <button class="btn mb-2" type="button" v-b-modal.modal-AgregarChofer
                        style="background-color: #26AF4A;color: white;  font-weight: bold;">
                        Agregar Chofer
                    </button>
                </div>

                <div class="row">

                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span>Estado</span>
                        </div>
                        <div class="d-flex justify-content-left">

                            <select class="form-control form-control-lg" id="estadoFiltro"
                                style="border: 1px #26AF4A  solid;" v-model="datosEditar.estado">
                                <option value="0">En Progreso</option>
                                <option value="1">Finalizado</option>
                                <option value="2">Cancelado</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="datosEditar.estado != 0" class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span>Fecha de Termino</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="date" id="zona" class="form-control form-control-lg" name="zona" required
                                v-model="datosEditar.fechaTermino" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-left">
                    <span>Observacion</span>
                </div>
                <div class="d-flex justify-content-left">
                    <textarea class="form-control mb-2" rows="2" v-model="datosEditar.observacion"
                        style=" border:1px #26AF4A solid;"></textarea>
                </div>
                <div class="d-flex justify-content-center">

                    <button class="btn" type="submit"
                        style="background-color: #26AF4A;color: white; width: 100%; font-weight: bold;">
                        Guardar Cambios
                    </button>
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
export default {

    name: 'Inventario',
    components: {

    },
    computed: {

    },
    data: function () {
        return {
            Proyectos: [], Turnos: [], Equipos: [],
            Cliente: '', auxProyectos: [], estado: '3',
            datosEditar: {}, auxTurnos: [],
            chofer: null, turnoAgregar: null, equipo: null,
            opcionesEquipo: [], fecha: null, cliente: null,
            fechaInicio: null, fechaLimite: null,
            EquiposAgregar: [], ChoferesAgregar: [],
            Choferes: [], direccionApi: null,
            observacion: null, ChoferesProyecto: [], sector: null,
            auxEquipos: [], patente: null, marca: null, turno: null,
            rut: null, nombre: null, auxChoferes: [],
            usuario: null, Sector:'',
            tk:'',
        }
    },
    methods: {
        buscarTurnos(datos) {
            this.datosEditar = datos
            const url = `${this.direccionApi}turnos/${datos.id}`
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                }
            }

            fetch(url, reqOpciones)
                .then(res => {
                    res.json().then(data => {
                        this.Turnos = data
                        console.log(data)
                    })
                });
        },
        async buscarEquipos() {
            this.Equipos = []
            this.auxEquipos = []
            const url = this.direccionApi + 'equipos'
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                }
            }
            fetch(url, reqOpciones)
                .then(res => {
                    res.json().then(data => {
                        this.Equipos = data
                        this.auxEquipos = data
                    })
                })
        },
        agregarEquipo(datos) {
            this.ChoferesAgregar[this.chofer].idEquipo = datos.patente
            this.$bvModal.hide('modal-AgregarEquipo')
            console.log(this.ChoferesAgregar)

        },
        async buscarChoferes() {
            this.Choferes = []
            this.auxChoferes = []
            const url = this.direccionApi + 'choferes'
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                }
            }
            fetch(url, reqOpciones)
                .then(res => {
                    if(!res.ok){
                        throw Error ('error')
                    }
                    res.json().then(data => {
                        
                        console.log(data)
                        data.forEach(element => {
                            element.usado = false
                            this.Choferes.push(element)
                            this.auxChoferes.push(element)
                        })
                    })
                }).catch(error =>{console.error(error);})
        },
        async filtroEquipos() {
            const patente = this.patente
            const marca = this.marca
            var lista = this.auxEquipos
            lista = lista.filter(objeto => {
                // Si no se especifica estado ni cliente, no se aplica filtro
                if (patente) {
                    return objeto.patente.includes(patente);
                }
                return true;
            });
            lista = lista.filter(objeto => {
                // Si no se especifica estado ni cliente, no se aplica filtro
                if (marca) {
                    return objeto.marca.includes(marca);
                }
                return true;
            });

            this.Equipos = lista
        },
        async filtroChoferes() {
            const rut = this.rut
            const nombre = this.nombre
            var lista = this.auxChoferes
            lista = lista.filter(objeto => {
                // Si no se especifica estado ni cliente, no se aplica filtro
                if (rut) {
                    return objeto.rut.includes(rut);
                }
                return true;
            });
            lista = lista.filter(objeto => {
                // Si no se especifica estado ni cliente, no se aplica filtro
                if (nombre) {
                    return objeto.nombre.includes(nombre);
                }
                return true;
            });

            this.Choferes = lista
        },
        async filtro() {
            const cliente = this.Cliente.toUpperCase()
            const estado = parseInt(this.estado)
            const sector = this.Sector.toUpperCase()
            var lista = this.auxProyectos
            lista = lista.filter(objeto => {
                // Filtrar por estado si estado es 3
                if (estado != 3) {
                    return objeto.estado === estado;

                }
                return true;
            });
            lista = lista.filter(objeto => {
                // Si no se especifica estado ni cliente, no se aplica filtro
                if (cliente) {
                    return objeto.cliente.includes(cliente);
                }
                return true;
            });
            lista = lista.filter(objeto => {
                // Si no se especifica estado ni cliente, no se aplica filtro
                if (sector) {
                    return objeto.sector.includes(sector);
                }
                return true;
            });
            this.Proyectos = lista
        },
        async pasoDatos(Datos) {
            this.datosEditar = Datos
        },
        async obtenerDatos(datos) {
            this.ChoferesAgregar = []
            this.pasoDatos(datos)
            this.buscarEquipos()
            await this.buscarChoferes()
            const url = `${this.direccionApi}turnos/${datos.id}`
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                }
            }

            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        console.log('error al comunicarse')
                    } else {


                        res.json().then(data => {
                            if (data.length) {

                                data.forEach(element => {

                                    const lista = this.Choferes
                                    const objetoAModificar = lista.find(objeto => objeto.rut === element.rut)
                                    if (objetoAModificar) {
                                        objetoAModificar.usado = true
                                        objetoAModificar.turno = element.turno
                                        objetoAModificar.idEquipo = element.idEquipo
                                        objetoAModificar.id = element.id
                                        this.ChoferesAgregar.push(objetoAModificar)

                                    }
                                })
                                this.auxChoferes = this.Choferes
                            }
                        })
                    }
                });
        },
        filtroTurnos() {
            var lista = this.auxTurnos
            const filtro = parseInt(this.selectTurno)
            lista = lista.filter(objeto => {
                if (filtro != 3) {
                    return objeto.tipoTurno === filtro
                }
                return true;
            })
            this.Turnos = lista
        },
        quitarChofer(index, datos) {
            this.ChoferesAgregar.splice(index, 1);
            datos.usado = false
        },
        agregarChofer(datos) {

            this.ChoferesAgregar.push(datos)
            datos.usado = true
            console.log(this.ChoferesAgregar)

        },
        async recargarProyectos() {
            this.Proyectos = []
            this.auxProyectos = []
            const url = this.direccionApi + 'proyectos'
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                }
            }
            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        console.log(`Error: ${res.status}`)
                    } else {
                        res.json().then(data => {
                            data.forEach(element => {
                                const fechaInicio = element.fechaInicio.split('T')
                                const fechaLimite = element.fechaLimite.split('T')
                                if (element.estado != 0) {

                                    const fechaTermino = element.fechaTermino.split('T')

                                    element.fechaTermino = fechaTermino[0]
                                }
                                element.fechaInicio = fechaInicio[0]
                                element.fechaLimite = fechaLimite[0]
                                this.Proyectos.push(element)
                                this.auxProyectos.push(element)
                            });
                        })
                    }
                })

        },
        async Editar() {
            let lista = []
            this.ChoferesAgregar.forEach(element => {
                const obj = {
                    idEquipo: element.idEquipo,
                    chofer: element.rut,
                    turno: element.turno
                }
                console.log(obj)
                lista.push(obj)
            })
            this.datosEditar.cliente = this.datosEditar.cliente.toUpperCase()
            this.datosEditar.sector = this.datosEditar.sector.toUpperCase()
            const url = this.direccionApi + 'editarProyecto'
            const datos = this.datosEditar
            datos.cliente = datos.cliente.toUpperCase()
            datos.turnos = lista
            console.log(datos)
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(datos)
            }

            fetch(url, reqOpciones).then(res => {
                this.datosEditar = {}
                console.log(res)
                this.$bvModal.hide('modal-Editar')
                this.recargarProyectos()
            })

        },
        async agregarProyecto() {
            let lista = []
            this.ChoferesAgregar.forEach(element => {
                const obj = {
                    idEquipo: element.idEquipo,
                    chofer: element.rut,
                    turno: element.turno
                }
                console.log(obj)
                lista.push(obj)
            })
            const url = this.direccionApi + 'proyectos'
            const datos = {
                cliente: this.cliente.toUpperCase(),
                sector: this.sector.toUpperCase(),
                fechaInicio: this.fechaInicio,
                fechaLimite: this.fechaLimite,
                usuario: this.usuario,
                observacion: this.observacion,
                turnos: lista
            }
            console.log(datos)
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(datos)
            }

            fetch(url, reqOpciones).then(res => {
                this.cliente = null
                this.sector = null
                this.fechaInicial = null
                this.fechaLimite = null
                this.ChoferesAgregar = []
                this.ChoferesAgregar = []
                this.observacion = null
                console.log(res)
                this.$bvModal.hide('modal-AgregarProyecto')
                this.recargarProyectos()
            })

        }
    },
    mounted() {
         var token = localStorage.getItem("token");
          var tipo = localStorage.getItem("tipo");
           this.tk=localStorage.getItem("token");
           this.usuario=localStorage.getItem("usuario");
         // console.log(token,tipo); 
          if(!token || tipo!=0){
            this.$router.push('/');
          }
          
        const direccionApi = 'https://api.trosales.cl/'
        //const direccionApi = 'http://localhost:3000/'
        this.direccionApi = direccionApi

        const url = this.direccionApi + 'proyectos'
        const reqOpciones = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                    'Authorization':this.tk,
            }
        }
        fetch(url, reqOpciones)
            .then(res => {
                if (!res.ok) {
                    console.log(`Error: ${res.status}`)
                } else {
                    res.json().then(data => {
                        data.forEach(element => {
                            const fechaInicio = element.fechaInicio.split('T')
                            const fechaLimite = element.fechaLimite.split('T')
                            if (element.estado != 0) {

                                const fechaTermino = element.fechaTermino.split('T')

                                element.fechaTermino = fechaTermino[0]
                            }
                            element.fechaInicio = fechaInicio[0]
                            element.fechaLimite = fechaLimite[0]
                            this.Proyectos.push(element)
                            this.auxProyectos.push(element)
                        });
                        console.log(this.Proyectos)
                    })
                }
            })


    }
}
</script>

<style>
h1 {
    color: #26AF4A;
    font-weight: bold;
    font-size: xx-large;
}

h2 {
    color: #26AF4A;
    font-weight: bold;
    font-size: x-large;
}

span {
    color: #26AF4A;
    font-size: large;
    font-weight: 500;
}
</style>