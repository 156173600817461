<template>
    <div class="content">
        <nav class="navbar navbar-expand-lg navbar-light shadow d-print-none" style="background-color: rgb(255,255,255);">
            <a class="navbar-brand" >
                <img width="150px" style="margin-left: 1%;" class="img-fluid" src="../assets/logo.jpeg">

            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
                style="color: white;background-color: white; margin-right: 1%;">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown" style="font-size: large;">
               <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" href="/alertas"><b-icon style=" color:#27AE4A" icon="house"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Alertas</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/trabajos"><b-icon style=" color:#27AE4A" icon="tools"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Trabajos</span></a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="/equipos"><b-icon style=" color:#27AE4A" icon="truck"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A;"> Equipos</span></a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="/inventario"><b-icon style=" color:#27AE4A" icon="box-seam"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Inventario</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/proyectos"><b-icon style=" color:#27AE4A" icon="briefcase"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Proyectos</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/usuarios"><b-icon style=" color:#27AE4A" icon="person"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Usuarios</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/cotizaciones"><b-icon style=" color:#27AE4A" icon="file-ruled"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Cotizaciones</span></a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="container-fluid col-md-11 shadow mt-3" style="background-color: white; border-radius: 10px;">
            <div class="d-flex justify-content-center">
                <h1 style="color:#26AF4A;"><b>Equipos</b></h1>
            </div>
            <div class="d-flex justify-content-center" >
                <div class="col-md-6">
                    <div class="input-group">
                    <input type="text" class="form-control" id="patenteBusqueda" placeholder="Ingrese patente" required style="border: 1px #26AF4A  solid;" >
                    <button type="submit" id="btnBuscar" class="btn btn-success" @click="buscarPatente()" >
                        
                        <i class="fas fa-search"></i>
                    </button>
                    </div><br>
                </div>
            </div>
            <div class="table-responsive" id="tabla">
                <table class="table table-striped table-bordered" style="display: block; font-family: Montserrat; font-size: 14px; overflow: auto; height: 500px;">
                    <thead style="position: sticky;top: 0">
                        <tr>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Patente</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Tipo
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Marca
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Modelo
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Año
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                               Kilometraje</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                               Horometro</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                               Sector</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                               Turno A</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                               Turno B</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Modificar KM/HR</th>
                            
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Editar</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Padrón</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Permiso</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                SOAP</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Poliza</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Revisión</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Mantenciones</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Equipos">
                            <td>{{ datos.patente }}</td>
                            <td>{{ datos.tipo }}</td>
                            <td>{{ datos.marca }}</td>
                            <td>{{ datos.modelo }}</td>
                            <td>{{ datos.año }}</td>
                            <td>{{ datos.kilometraje }}</td>
                            <td>{{ datos.horometro }}</td>
                            <td>{{ datos.sector }}</td>
                            <td>{{ datos.turnoA }}</td>
                            <td>{{ datos.turnoB }}</td>
                            <td>
                                <div class="d-flex justify-content-center aling-items-center">
                                    <button v-b-modal.modal-AgregarCarga class="btn" @click="modalCarga(datos)"
                                        style="background-color: #26AF4A;color: white;">
                                        <b-icon icon="plus-lg" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>

                           

                            <td>
                                <div class="d-flex justify-content-center aling-items-center">
                                    <button v-b-modal.modal-editar class="btn" @click="editarEquipo(datos)"
                                        style="background-color: #26AF4A;color: white;">
                                        <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>
                            <td v-if="datos.padron"> <button class="btn btn-success" @click="verPadron(datos.patente,datos.padron)">
                                        VER
                                    </button>
                            </td>
                            <td v-else> <button class="btn btn-warning" @click="verPadron(datos.patente,datos.padron)">
                                        SUBIR
                                    </button>
                            </td>
                            <td v-if="datos.permiso"> <button class="btn btn-success" @click="verPermiso(datos.patente,datos.permiso)">
                                        VER
                                    </button>
                            </td>
                            <td v-else> <button class="btn btn-warning" @click="verPermiso(datos.patente,datos.permiso)">
                                        SUBIR
                                    </button>
                            </td>
                            <td v-if="datos.soap"> <button class="btn btn-success" @click="verSoap(datos.patente,datos.soap)">
                                        VER
                                    </button>
                            </td>
                            <td v-else> <button class="btn btn-warning" @click="verSoap(datos.patente,datos.soap)">
                                        SUBIR
                                    </button>
                            </td>
                            <td v-if="datos.poliza"> <button class="btn btn-success" @click="verPoliza(datos.patente,datos.poliza)">
                                        VER
                                    </button>
                            </td>
                            <td v-else> <button class="btn btn-warning" @click="verPoliza(datos.patente,datos.poliza)">
                                        SUBIR
                                    </button>
                            </td>
                            <td v-if="datos.revision"> <button class="btn btn-success" @click="verRevision(datos.patente,datos.revision)">
                                        VER
                                    </button>
                            </td>
                            <td v-else> <button class="btn btn-warning" @click="verRevision(datos.patente,datos.revision)">
                                        SUBIR
                                    </button>
                            </td>
                             <td>
                                <div class="d-flex justify-content-center aling-items-center">
                                    <button v-b-modal.modal-mantenciones @click="buscarOrdenTrabajo(datos.patente)" class="btn btn-secondary">
                        
                                        <b-icon icon="file-text" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-center" style="margin-top: 1%;padding-bottom: 1%;">
                <button @click="agregarEquipo()" class="btn" style="background-color: #26AF4A;color: white;"
                    v-b-modal.modal-Agregar>
                    Agregar Equipo
                </button>
            </div>
        </div>
       <b-modal id="modal-Agregar" size="lg" hide-footer  centered>
            <form @submit.prevent="AgregarEquipo()">
                <div class="d-flex justify-content-center">
                    <h2 style="color:#26AF4A;font-weight: bold;">Registrar Equipo</h2>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Patente</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="patente" class="form-control form-control-lg" name="patente"
                                required v-model="patente" style="border: 1px #26AF4A  solid;" maxlength="7">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Tipo</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="tipo" class="form-control form-control-lg" name="tipo"
                                required v-model="tipo" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Marca</span>
                        </div>
                        <div class="d-flex justify-content-left">
                          <input type="text" id="marca" class="form-control form-control-lg" name="marca"
                                required v-model="marca" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Modelo</span>
                        </div>
                        <div class="d-flex justify-content-left">
                          <input type="text" id="modelo" class="form-control form-control-lg" name="modelo"
                                required v-model="modelo" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Año</span>
                        </div>
                        <div class="d-flex justify-content-left">
                          <input type="text" id="año" class="form-control form-control-lg" name="año"
                                required v-model="año" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Kilometraje</span>
                        </div>
                        <div class="d-flex justify-content-left">
                          <input type="text" id="kilometraje" class="form-control form-control-lg" name="kilometraje"
                                required v-model="kilometraje" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Horometro</span>
                        </div>
                        <div class="d-flex justify-content-left">
                          <input type="text" id="horometro" class="form-control form-control-lg" name="horometro"
                                required v-model="horometro" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                
                <div class="d-flex justify-content-center">
                    <button type="submit" class="btn" style="background-color: #26AF4A; color: white;">
                        Registrar Equipo
                    </button>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-AgregarCarga" size="md" hide-footer hide-header centered>
           
                <div class="d-flex justify-content-center">
                    <h2 style="color:#26AF4A; font-weight: bold;">Agregar Carga</h2>
                </div>
                <div class="d-flex justify-content-center">
            <div class="row">
                <div class="col-sm"><br> 
                    <h6 style="color:#005F33">Elija un tipo</h6>
                </div>
            </div>
        </div>
         <div class="d-flex justify-content-center">
            <div class="row">
                <div class="col-sm">
                    <select class="form-select" id="tipoCarga"  @change="cambiarTipoCarga()">
                        <option value="kilometraje">Kilometraje</option>
                        <option value="horas">Horas</option>
                    </select>   
                </div>
               
            </div>
        </div><br>
        <div id='cargaKilometraje' >
    <form @submit.prevent="actualizarKilometraje()">
        <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Kilometraje Actual</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text"  id="kilometrajeActual" class="form-control form-control-lg"
                                name="kilometrajeActual" required v-model="kilometrajeActual" style="border: 1px #26AF4A  solid;" readonly>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Nuevo Kilometraje</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="number" min="0" id="kilometrajeNuevo" class="form-control form-control-lg"
                                name="kilometrajeNuevo" required v-model="kilometrajeNuevo" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                
               
                <div class="d-flex justify-content-center">
                    <button type="submit" class="btn" style="background-color: #26AF4A; color: white;">
                        Actualizar Kilometraje
                    </button>
                </div>
                
            </form>
             </div>

  <div id="cargaHorometro" style="display:none">
    <form @submit.prevent="actualizarHorometro()">
        <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Horometro Actual</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text"  id="horometroActual" class="form-control form-control-lg"
                                name="horometroActual" required v-model="horometroActual" style="border: 1px #26AF4A  solid;" readonly>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Horometro Nuevo</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="number" min="0" id="horometroNuevo" class="form-control form-control-lg"
                                name="horometroNuevo" required v-model="horometroNuevo" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                
               
                <div class="d-flex justify-content-center">
                    <button type="submit" class="btn" style="background-color: #26AF4A; color: white;">
                        Actualizar Horometro
                    </button>
                </div>
                
            </form>
             </div>


        </b-modal>


        <b-modal id="modal-mantenciones" size="lg" hide-footer hide-header centered>
           <div class="table-responsive" id="tabla">
                <table class="table table-striped table-bordered">
                    <thead style="position: sticky;top: 0">
                        <tr>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">Patente
                            </th>
                            
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                KM</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                HM</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Observación</th>
                          
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Items</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Fecha</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Usuario</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Mantenciones">
                            <td>{{ datos.patente }}</td>
                            
                            <td>{{ datos.kilometraje }}</td>
                            <td>{{ datos.horometro }}</td>
                            <td>{{ datos.observacion }}</td>
                            <td>{{ datos.items }}</td>
                            <td>{{ datos.fechaMantencion}}</td>
                            <td>{{ datos.mecanico}}</td>
                           
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>

        <b-modal id="modal-editar" size="lg" hide-footer  centered>
            <form @submit.prevent="guardarEdicion()">
                <div class="d-flex justify-content-center">
                    <h2 style="color:#26AF4A;font-weight: bold;">Editar Equipo</h2>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Patente</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="patenteEditar" class="form-control form-control-lg" name="patenteEditar"
                                required v-model="patenteEditar" style="border: 1px #26AF4A  solid;" maxlength="7" readonly>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Tipo</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="tipoEditar" class="form-control form-control-lg" name="tipoEditar"
                                required v-model="tipoEditar" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Marca</span>
                        </div>
                        <div class="d-flex justify-content-left">
                          <input type="text" id="marcaEditar" class="form-control form-control-lg" name="marcaEditar"
                                required v-model="marcaEditar" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Modelo</span>
                        </div>
                        <div class="d-flex justify-content-left">
                          <input type="text" id="modeloEditar" class="form-control form-control-lg" name="modeloEditar"
                                required v-model="modeloEditar" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Año</span>
                        </div>
                        <div class="d-flex justify-content-left">
                          <input type="text" id="añoEditar" class="form-control form-control-lg" name="añoEditar"
                                required v-model="añoEditar" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Kilometraje</span>
                        </div>
                        <div class="d-flex justify-content-left">
                          <input type="text" id="kilometrajeEditar" class="form-control form-control-lg" name="kilometrajeEditar"
                                required v-model="kilometrajeEditar" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Horometro</span>
                        </div>
                        <div class="d-flex justify-content-left">
                          <input type="text" id="horometroEditar" class="form-control form-control-lg" name="horometroEditar"
                                required v-model="horometroEditar" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                
                <div class="d-flex justify-content-center">
                    <button type="submit" class="btn" style="background-color: #26AF4A; color: white;">
                        Editar Equipo
                    </button>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>

import Swal from 'sweetalert2'

export default {

    name: 'Inventario',
    components: {

    },
    computed: {

    },
    data: function () {
        return {
            Equipos: [], cargaAgregado: null, datosEditar: {},
            patente: null, auditado: false, movimientosEquipo: [],
            Mantenciones:[],
            tipo:'',
            marca:'',
            modelo:'',
            año:'',
            kilometraje:'',
            horometro:'',
            
            padron:'',
            permiso:'',
            soap:'',
            poliza:'',
            revision:'',

            patenteEditar:'', 
            tipoEditar:'',
            marcaEditar:'',
            modeloEditar:'',
            añoEditar:'',
            kilometrajeEditar:'',
            horometroEditar:'',

            patenteCarga:'',
            kilometrajeActual:'',
            horometroActual:'',
            kilometrajeNuevo:'',
            horometroNuevo:'',
            tipoCarga:'kilometraje',
            // urlBd:'http://localhost:3000/',
             urlBd:'https://api.trosales.cl/',
            tk:'',
           usuario:''
        }
    },
    methods: {
        async buscarPatente(){
            var patente=document.getElementById("patenteBusqueda").value
            const url = this.urlBd+'buscarPatente/'+patente
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
            }
            fetch(url, reqOpciones).then(res => {
                
                res.json().then(data => {
                    console.log(data.message)
                    if(data.message!="error"){
                       this.Equipos=data
                    }else{
                        this.Equipos=[]
                       Swal.fire({
                        icon: 'info',
                        title: 'Patente no encontrada'
                    })
                    }
                   
                })
            })
        },

        async buscarOrdenTrabajo(patente){
            this.Mantenciones=[]
           // var patente=document.getElementById("patenteBuscar").value;
            const url = this.urlBd+'buscarOrdenTrabajo2/'+patente
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
            }
            fetch(url, reqOpciones).then(res => {
                let y=this
                res.json().then(data => {
                    if(data.length>0){
                       data.forEach(function(dato){
                        var x=new Object
                        x.id=dato.id
                        x.patente=dato.patente
                        x.tipo=dato.tipo
                        x.modelo=dato.modelo
                        x.marca=dato.marca
                        x.año=dato.año
                        x.kilometraje=dato.kilometraje
                        x.horometro=dato.horometro
                        x.fechaMantencion=dato.fechaMantencion.split("T")[0]
                        x.observacion=dato.observacion
                        x.mecanico=dato.mecanico
                        x.items=dato.ConcatenatedItems 
                        y.Mantenciones.push(x)
                            
                       })
                    }else{
                       Swal.fire({
                        icon: 'warning',
                        title: 'No se encontraron trabajos'
                    })
                    }
                   
                })
            })
        },


        verPadron(patente,archivo){
           this.$router.push('/padron?patente='+patente+"&archivo="+archivo);
        },
         verPermiso(patente,archivo){
           this.$router.push('/permiso?patente='+patente+"&archivo="+archivo);
        },
        verSoap(patente,archivo){
           this.$router.push('/soap?patente='+patente+"&archivo="+archivo);
        },
        verPoliza(patente,archivo){
           this.$router.push('/poliza?patente='+patente+"&archivo="+archivo);
        },
        verRevision(patente,archivo){
           this.$router.push('/revision?patente='+patente+"&archivo="+archivo);
        },

        async AgregarEquipo() {

            const datos = {
                patente: this.patente,
                tipo: this.tipo,
                marca: this.marca,
                modelo: this.modelo,
                año: this.año,
                kilometraje: this.kilometraje,
                horometro: this.horometro,
                cargaTrabajo: this.cargaTrabajo,
                padron: this.padron,
                permiso: this.permiso,
                soap: this.soap,
                poliza: this.poliza,
                revision: this.revision,
            }

            const url = this.urlBd+'registrarEquipo'
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(datos)
            }

            fetch(url, reqOpciones).then(res => {
                console.log(res)
               
                this.recargarEquipos()

                this.patente = null
                this.marca = null
                this.modelo = null
                this.cargaTrabajo = null

                res.json().then(data => {

                    this.$bvModal.hide('modal-Agregar')

                    Swal.fire({
                        icon: 'success',
                        title: 'Equipo Registrado'
                    })

                    console.log(data)
                })
            })
        },
        async AgregarCarga() {

            const datosEditar = this.datosEditar
            const cargaNueva = this.cargaAgregado

            datosEditar.cargaTrabajo = datosEditar.cargaTrabajo + + cargaNueva

            console.log(datosEditar)

            this.SubirCambios(datosEditar)
        },

        editarEquipo(datos){
            console.log(datos)
            this.patenteEditar=datos.patente
            this.tipoEditar=datos.tipo
            this.marcaEditar=datos.marca
            this.modeloEditar=datos.modelo
            this.añoEditar=datos.año
            this.kilometrajeEditar=datos.kilometraje
            this.horometroEditar=datos.horometro
        },

         async guardarEdicion() {

            const datos = {
                patente: this.patenteEditar,
                tipo: this.tipoEditar,
                marca: this.marcaEditar,
                modelo: this.modeloEditar,
                año: this.añoEditar,
                kilometraje: this.kilometrajeEditar,
                horometro: this.horometroEditar,
               
            }

            const url = this.urlBd+'editarEquipo'
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(datos)
            }

            fetch(url, reqOpciones).then(res => {
                console.log(res)
               
                this.recargarEquipos()

               

                res.json().then(data => {

                    this.$bvModal.hide('modal-editar')

                    Swal.fire({
                        icon: 'success',
                        title: 'Equipo Editado'
                    })

                    console.log(data)
                })
            })
        },

        modalCarga(datos){
            this.patenteCarga=datos.patente
            this.kilometrajeActual=datos.kilometraje
            this.horometroActual=datos.horometro
        },

        cambiarTipoCarga(){
            if(document.getElementById("tipoCarga").value=='kilometraje'){
                document.getElementById("cargaKilometraje").style.display="block"
                document.getElementById("cargaHorometro").style.display="none"
            }else{
                document.getElementById("cargaKilometraje").style.display="none"
                document.getElementById("cargaHorometro").style.display="block"
            }
        },

        actualizarKilometraje(){
            if(this.kilometrajeNuevo>this.kilometrajeActual){
                
             const datos = {
                patente:this.patenteCarga,
                kilometraje: parseInt(this.kilometrajeNuevo),
            }

            const url = this.urlBd+'actualizarKilometraje'
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(datos)
            }

            fetch(url, reqOpciones).then(res => {
                console.log(res)
               
                this.recargarEquipos()
                res.json().then(data => {
                    this.$bvModal.hide('modal-AgregarCarga')
                    Swal.fire({
                        icon: 'success',
                        title: 'Kilometraje Editado'
                    })
                    //FALTA REGISTRAR UN HISTORICO DE CARGAS DE TRABAJO
                    console.log(data)
                })
            })
            }else{
                Swal.fire({
                        icon: 'warning',
                        title: 'Aviso',
                        text:'El kilometraje debe ser mayor que el actual'
                    })
            }   
        },


        actualizarHorometro(){
            if(this.horometroNuevo>this.horometroActual){
                
             const datos = {
                patente:this.patenteCarga,
                horometro: parseInt(this.horometroNuevo),
            }

            const url = this.urlBd+'actualizarHorometro'
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(datos)
            }

            fetch(url, reqOpciones).then(res => {
                console.log(res)
               
                this.recargarEquipos()
                res.json().then(data => {
                    this.$bvModal.hide('modal-AgregarCarga')
                    Swal.fire({
                        icon: 'success',
                        title: 'Horometro Editado'
                    })
                    //FALTA REGISTRAR UN HISTORICO DE CARGAS DE TRABAJO
                    console.log(data)
                })
            })
            }else{
                Swal.fire({
                        icon: 'warning',
                        title: 'Aviso',
                        text:'El horometro debe ser mayor que el actual'
                    })
            }   
        },



        
        async SubirCambios(dato) {

            const url = ''
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(dato)
            }

            fetch(url, reqOpciones).then((res) => {
                console.log(res.status)
                this.recargarItems()
                this.stockAgregado = 0

                res.json().then(data => {

                    this.$bvModal.hide('modal-AgregarCarga')
                    this.$bvModal.hide('modal-editar')

                    Swal.fire({
                        icon: 'success',
                        title: 'Cambio Realizado'
                    })

                    console.log(data)
                })

            }).catch(err => {
                console.log(err)
            })
        },
        async recargarEquipos() {

            const url = this.urlBd+'equipos'
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                }
            }
            
            fetch(url, reqOpciones)
                .then(res => {
                    if (!res) {
                        console.log(`Error: ${res.status}`)
                    } else {
                        res.json().then(data => {
                            this.Equipos = data
                        })
                    }
                })
                .catch(err => {
                console.log(err)
                })
        },




        async pasoDatos(Datos) {

            this.datosEditar = Datos
            this.patente = Datos.nombre
            this.auditado = false
            this.movimientosEquipo = []
            this.cargaAgregado = null
        },

    },
    mounted() {
         var token = localStorage.getItem("token");
          var tipo = localStorage.getItem("tipo");
           this.tk=localStorage.getItem("token");
           this.usuario=localStorage.getItem("usuario");
         // console.log(token,tipo); 
          if(!token || tipo!=0){
            this.$router.push('/');
          }
        this.recargarEquipos()

    }
}
</script>

<style>
h1 {
    color: #26AF4A;
    font-weight: bold;
    font-size: xx-large;
}

h2 {
    color: #26AF4A;
    font-weight: bold;
    font-size: x-large;
}

span {
    color: #26AF4A;
    font-size: large;
    font-weight: 500;
}
</style>