<template>
    <div class="content">
        <nav class="navbar navbar-expand-lg navbar-light shadow d-print-none" style="background-color: rgb(255,255,255);">
            <a class="navbar-brand">
                <img width="150px" style="margin-left: 1%;" class="img-fluid" src="../assets/logo.jpeg">

            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
                style="color: white;background-color: white; margin-right: 1%;">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown" style="font-size: large;">
                 <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" href="/alertas"><b-icon style=" color:#27AE4A" icon="house"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Alertas</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/trabajos"><b-icon style=" color:#27AE4A" icon="tools"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Trabajos</span></a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="/equipos"><b-icon style=" color:#27AE4A" icon="truck"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A;"> Equipos</span></a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="/inventario"><b-icon style=" color:#27AE4A" icon="box-seam"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Inventario</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/proyectos"><b-icon style=" color:#27AE4A" icon="briefcase"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Proyectos</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/usuarios"><b-icon style=" color:#27AE4A" icon="person"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Usuarios</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/cotizaciones"><b-icon style=" color:#27AE4A" icon="file-ruled"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Cotizaciones</span></a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="container-fluid col-md-11 shadow mt-3" style="background-color: white; border-radius: 10px;">
            <div class="d-flex justify-content-center">
                <h1 style="color:#26AF4A;font-weight: bold;">Inventario</h1>
            </div>
            <div class="table-responsive" id="tabla">
                <table class="table table-striped table-bordered">
                    <thead style="position: sticky;top: 0">
                        <tr>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">Item
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Descripción</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Stock</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Auditar</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Editar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Items">
                            <td>{{ datos.nombre }}</td>
                            <td>{{ datos.descripcion }}</td>
                            <td>
                                <span v-if="datos.stock < datos.stockMinimo" style="color: red; font-weight: bold;">{{
                                    datos.stock }}</span>
                                <span v-else-if="datos.stock == datos.stockMinimo"
                                    style="color: rgb(255, 98, 0);font-weight: bold;">{{ datos.stock }}</span>
                                <span v-else style="color: green; font-weight: bold;">{{ datos.stock }}</span>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center aling-items-center">
                                    <button disable v-b-modal.modal-Auditar @click="Auditar(datos)" class="btn"
                                        style="background-color: #26AF4A;color: white;">
                                        <b-icon icon="file-text" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>

                            <td>
                                <div class="d-flex justify-content-center aling-items-center">
                                    <button v-b-modal.modal-Editar class="btn" @click="pasoDatos(datos)"
                                        style="background-color: #26AF4A;color: white;">
                                        <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="container-fluid" style="margin-top: 1%;padding-bottom: 1%;">
                <div class="row">
                    <div class="col-md-6">
                        <button @click="vaciarVariables()" class="btn"
                            style="background-color: #26AF4A;color: white;width: 100%;font-weight:600;"
                            v-b-modal.modal-Agregar>
                            Crear Item
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button v-b-modal.modal-AgregarStock class="btn" @click="preAgregarCompra()"
                            style="background-color: #26AF4A;color: white;width: 100%;font-weight: 600;">
                            Agregar Orden de Compra
                        </button>
                    </div>
                </div>

            </div>
        </div>
        <b-modal id="modal-AgregarStock" size="xl" hide-footer hide-header>
            <form @submit.prevent="AgregarStock()">
                <div class="d-flex justify-content-center">
                    <h2 style="color:#26AF4A; font-weight: bold;">Agregar Orden de Compra</h2>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>N° de factura</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="text" id="factura" class="form-control form-control-lg" name="factura" required
                                    v-model="nFactura" style="border: 1px #26AF4A  solid;">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Rut proveedor</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <div class="input-group">
                                    <input @input="formatoRUT();" type="text" class="form-control form-control-lg"
                                        v-model="RUTproveedor" maxlength="10" aria-label="Recipient's username"
                                        aria-describedby="basic-addon2" style="border: 1px #26AF4A  solid;">
                                    <div class="input-group-append">
                                        <button @click="buscarProveedor()" class="btn btn-outline-secondary btn-lg"
                                            type="button"
                                            style="border: 1px #26AF4A solid; color:white;background-color: #26AF4A;border-top-left-radius: 0; border-bottom-left-radius: 0;">
                                            <b-icon icon="search" aria-hidden="true"></b-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Nombre proveedor</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="text" id="nombreProveedor" class="form-control form-control-lg"
                                    name="nombreProveedor" required v-model="nombreProveedor"
                                    style="border: 1px #26AF4A  solid;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Fecha emisión de factura</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="date" id="emisionFactura" class="form-control form-control-lg"
                                    name="emisionFactura" required v-model="emisionFactura"
                                    style="border: 1px #26AF4A  solid;">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Fecha vencimiento de factura</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="date" id="vencimientoFactura" class="form-control form-control-lg"
                                    name="vencimientoFactura" required v-model="vencimientoFactura"
                                    style="border: 1px #26AF4A  solid;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="table-responsive" id="tabla" style="border-radius: 5px;">
                        <table class="table table-striped table-bordered">
                            <thead style="position: sticky;top: 0">
                                <tr>
                                    <th
                                        style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                        Item
                                    </th>
                                    <th
                                        style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                        Cantidad</th>
                                    <th
                                        style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                        Valor Unitario</th>
                                    <th
                                        style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                        Valor Total</th>
                                    <th
                                        style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                        Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="index" v-for="(datos, index) in cantidadCompra">
                                    <td>
                                        <select id="selectItems" class="form-control form-control-lg"
                                            v-model="cantidadCompra[index].item">
                                            <option v-for="opcion in opciones" :key="opcion.id" :value="opcion.id">{{
                                                opcion.nombre }}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="number" id="cantidadItem" class="form-control form-control-lg"
                                            name="emisionFactura" @input="total(index)" required
                                            v-model="cantidadCompra[index].cantidad" min="0" step="1"
                                            style="border: 1px #26AF4A  solid;">
                                    </td>
                                    <td>
                                        <input type="number" @input="total(index)" id="valorUItem"
                                            class="form-control form-control-lg" name="emisionFactura" required
                                            v-model="cantidadCompra[index].valorU" min="0" step="10"
                                            style="border: 1px #26AF4A  solid;">
                                    </td>
                                    <td>
                                        <input @input="modificarValoresFinal" type="number" id="valorTItem"
                                            class="form-control form-control-lg" name="emisionFactura" required
                                            v-model="cantidadCompra[index].valorT" min="0" step="10"
                                            style="border: 1px #26AF4A  solid;">
                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-center aling-items-center">
                                            <button class="btn" @click="eliminarRow(index)" type="button"
                                                style="background-color: red;color: white;">
                                                <b-icon icon="trash" aria-hidden="true"></b-icon>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">

                        <button class="btn" @click="agregarColumna()" type="button"
                            style="background-color: #26AF4A;color: white; width: 100%; font-weight: bold;">
                            Agregar Item
                        </button>
                    </div>
                    <div class="col-md-6">

                        <button class="btn" v-b-modal.modal-Agregar type="button"
                            style="background-color: #26AF4A;color: white;width: 100%; font-weight: bold;">
                            Crear Item
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Valor neto</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="number" id="emisionFactura" readonly class="form-control form-control-lg"
                                    name="emisionFactura" required v-model="valorNeto" min="0" step="10"
                                    style="border: 1px #26AF4A  solid;background-color: #e7e7e7;">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>IVA</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="number" id="vencimientoFactura" class="form-control form-control-lg"
                                    name="vencimientoFactura" required v-model="IVA" readonly
                                    style="border: 1px #26AF4A  solid;background-color: #e7e7e7;">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="container-fluid mb-1">
                            <div class="d-flex justify-content-left">
                                <span>Total</span>
                            </div>
                            <div class="d-flex justify-content-left">
                                <input type="number" id="valorTotal" class="form-control form-control-lg" name="valorTotal"
                                    required v-model="valorTotal" readonly
                                    style="border: 1px #26AF4A  solid;background-color: #e7e7e7;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid mb-1">
                    <div class="d-flex justify-content-left">
                        <span>Método de pago (Restante: {{ valorRestante }})</span>
                    </div>
                </div>
                <div class="container-fluid col-md-6">
                    <div class="table-responsive" id="tabla" style="border-radius: 5px;">
                        <table class="table table-striped table-bordered">
                            <thead style="position: sticky;top: 0">
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Tipo de Pago</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Monto</th>
                            </thead>

                            <tbody>
                                <tr :key="i" v-for="(tipoPago, i) in listaTipoPago">
                                    <td>{{ tipoPago.Tipo }}</td>
                                    <td>
                                        <div class="d-flex justify-content-center">
                                            <input @input="restarTotal()" type="number" class="form-control form-control-lg"
                                                name="valorRipoPago" required v-model="tipoPago.valor" min="0"
                                                style="border: 1px #26AF4A  solid;">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="container-fluid mb-3">
                    <div class="d-flex justify-content-left">
                        <span>Observación</span>
                    </div>
                    <div class="d-flex justify-content-left">
                        <textarea class="form-control" rows="2" v-model="Observacion"
                            style=" border:1px #26AF4A solid;"></textarea>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <button type="submit" class="btn" style="background-color: #26AF4A; color: white;font-weight: 600;">
                        Agregar Orden de Compra
                    </button>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-Editar" size="md" hide-footer hide-header centered>
            <form @submit.prevent="EditarItem()">
                <div class="d-flex justify-content-center">
                    <h2 style="color:#26AF4A; font-weight: bold">Editar Item</h2>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Nombre</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input @input="hayCambios = true" type="text" id="cantAgregar"
                                class="form-control form-control-lg" name="cantAgregar" required v-model="nombre"
                                style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Descripción</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input @input="hayCambios = true" type="text" id="cantAgregar"
                                class="form-control form-control-lg" name="cantAgregar" required v-model="descripcion"
                                style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex justify-content-center mb-2">
                            <div class="container-fluid">
                                <div class="d-flex justify-content-left">
                                    <span>Stock</span>
                                </div>
                                <div class="d-flex justify-content-left">
                                    <input @input="hayCambios = true" type="number" min="0" id="cantAgregar"
                                        class="form-control form-control-lg" name="cantAgregar" required v-model="stock"
                                        style="border: 1px #26AF4A  solid;background-color: white">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="d-flex justify-content-center mb-2">
                            <div class="container-fluid">
                                <div class="d-flex justify-content-left">
                                    <span>Stock Minimo</span>
                                </div>
                                <div class="d-flex justify-content-left">
                                    <input @input="hayCambios = true" type="number" min="0" id="cantAgregar"
                                        class="form-control form-control-lg" name="cantAgregar" placeholder="Sin Límite = 0"
                                        required v-model="stockMinimo" style="border: 1px #26AF4A  solid;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <h6 v-if="!itemActivo" style="color: #26AF4A;">Desactivado</h6>
                    <h6 v-else style="color: #797979;">Desactivado</h6>
                    <label class="switch"><input @input="hayCambios = true" type="checkbox" v-model="itemActivo" />
                        <div></div>
                    </label>
                    <h6 v-if="itemActivo" style="color: #26AF4A;">Activado</h6>
                    <h6 v-else style="color: #797979;">Activado</h6>
                </div>
                <div class="d-flex justify-content-center">
                    <button v-if="hayCambios" type="submit" class="btn"
                        style="background-color: #26AF4A; color: white;font-weight: 600;">
                        Guardar Cambios
                    </button>
                    <button v-else type="submit" disabled class="btn"
                        style="background-color: #26AF4A; color: white;font-weight: 600;">
                        Guardar Cambios
                    </button>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-Agregar" size="md" hide-footer hide-header centered>
            <form @submit.prevent="AgregarItem()">
                <div class="d-flex justify-content-center">
                    <h2 style="color:#26AF4A;font-weight: bold;">Crear Nuevo Item</h2>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Nombre</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="cantAgregar" class="form-control form-control-lg" name="cantAgregar"
                                required v-model="nombre" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Descripción</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="cantAgregar" class="form-control form-control-lg" name="cantAgregar"
                                required v-model="descripcion" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Stock</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="number" min="0" id="cantAgregar" class="form-control form-control-lg"
                                name="cantAgregar" required v-model="stock" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2">
                    <div class="container-fluid">
                        <div class="d-flex justify-content-left">
                            <span>Stock Minimo</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="number" min="0" id="cantAgregar" class="form-control form-control-lg"
                                name="cantAgregar" v-model="stockMinimo" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <button type="submit" class="btn" style="background-color: #26AF4A; color: white;font-weight: 600;">
                        Agregar Item
                    </button>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-Auditar" size="xl" hide-footer hide-header>
            <form @submit.prevent="Auditar()">
                <div class="d-flex justify-content-center">
                    <h2 style="color:#26AF4A;">Auditar Item: <strong>{{ this.datosEditar.nombre }}</strong></h2>
                </div>
                <div class="table-responsive" id="tabla">
                    <table class="table table-striped table-bordered" style="display.block">
                        <thead style="position: sticky;top: 0">
                            <tr>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Tipo de Movimiento</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Cantidad</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Fecha de Movimiento</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Patente / Factura</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="index" v-for="(datos, index) in movimientosItem">
                                <td>{{ datos.salida }}</td>
                                <td>{{ datos.cantidad }}</td>
                                <td>{{ datos.fecha }}</td>
                                <td>{{ datos.detalleFinal }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>

import Swal from 'sweetalert2'
import { format, clean, validate } from 'rut.js'

export default {

    name: 'Inventario',
    components: {

    },
    computed: {

    },
    data: function () {
        return {
            Items: [], stockAgregado: 0, datosEditar: {},
            nombre: null, descripcion: null, stock: null, stockMinimo: null,
            fechaInicial: null, fechaFinal: null, movimientosItem: [], auditado: false,
            itemActivo: null, hayCambios: null, Observacion: null,
            IVA: 0, valorNeto: 0, valorTotal: 0,
            nombreProveedor: null, RUTproveedor: null, vencimientoFactura: null, emisionFactura: null,
            nFactura: null, listaTipoPago: [], rutValido: true, cantidadCompra: [],
            opciones: [], direccionApi: null, valorRestante: null, Detalles: [],tk:'',
           usuario:''
        }
    },
    methods: {

        async AgregarItem() {
            const stockMinimo = this.stockMinimo ? this.stockMinimo : 0
            const datos = {
                nombre: this.nombre,
                descripcion: this.descripcion,
                stock: this.stock,
                stockMinimo: stockMinimo,
                activo: true
            }

            const url = this.direccionApi + 'registrarItem'
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(datos)
            }

            fetch(url, reqOpciones).then(res => {

                this.recargarItems()

                this.nombre = null
                this.descripcion = null
                this.stock = null
                this.stockMinimo = null
                res.json().then(data => {

                    this.$bvModal.hide('modal-Agregar')

                    Swal.fire({
                        icon: 'success',
                        title: 'Item Agregado'
                    })

                    console.log(data)
                })
            })
        },

        async EditarItem() {
            this.$bvModal.hide('modal-Editar')
            // Mostrar el diálogo Swal con un índice z alto
            const { value: password } = await Swal.fire({
                title: 'Ingrese Clave Administrador',
                input: 'password',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Confirmar',
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading(),
                customClass: {
                    input: 'custom-input-class' // Clase CSS personalizada
                }
            });

            // Verificar la contraseña ingresada
            if (password === 'trosales') {
                const datosEditar = {
                    id: this.datosEditar.id,
                    nombre: this.nombre,
                    descripcion: this.descripcion,
                    stock: this.stock,
                    stockMinimo: this.stockMinimo,
                    activo: this.itemActivo
                };

                this.SubirCambios(datosEditar);
            } else if (password !== undefined) {
                // La contraseña es incorrecta
                Swal.fire({
                    icon: 'error',
                    title: 'Contraseña Incorrecta!!!'
                });
            }
        },


        async AgregarStock() {
            if (this.valorRestante != 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Total pagado es distinto al Total a pagar'
                })
            } else {


                const url = this.direccionApi + 'agregarOrdenCompra'
                const datos = {
                    nFactura: this.nFactura,
                    rut: this.RUTproveedor,
                    nombre: this.nombreProveedor,
                    fEmision: this.emisionFactura,
                    fVencimiento: this.vencimientoFactura,
                    neto: this.valorNeto,
                    iva: this.IVA,
                    total: this.valorTotal,
                    observacion: this.Observacion,
                    lista: this.cantidadCompra,
                    debito: this.listaTipoPago[1].valor,
                    credito: this.listaTipoPago[2].valor,
                    cheque: this.listaTipoPago[3].valor,
                    contado: this.listaTipoPago[0].valor
                }
                const reqOpciones = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    'Authorization':this.tk,
                    },
                    body: JSON.stringify(datos)
                }

                fetch(url, reqOpciones).then(res => {
                    this.datosEditar = {}
                    this.nFactura = null
                    this.RUTproveedor = null
                    this.nombreProveedor = null
                    this.vencimientoFactura = null
                    this.vencimientoFactura = null
                    this.valorNeto = null
                    this.IVA = null
                    this.total = null
                    this.Observacion = null
                    this.cantidadCompra = []
                    console.log(res)
                    this.$bvModal.hide('modal-AgregarStock')
                    this.recargarItems()
                })
            }
        },
        async SubirCambios(dato) {

            const url = this.direccionApi + 'editarItem'
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(dato)
            }

            fetch(url, reqOpciones).then((res) => {
                console.log(res.status)
                this.recargarItems()
                this.stockAgregado = 0

                res.json().then(data => {

                    this.$bvModal.hide('modal-AgregarStock')
                    this.$bvModal.hide('modal-Editar')

                    Swal.fire({
                        icon: 'success',
                        title: 'Cambio Realizado'
                    })

                    console.log(data)
                })

            }).catch(err => {
                console.log(err)
            })
        },
        async recargarItems() {

            const url = this.direccionApi + 'items'
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                }
            }

            fetch(url, reqOpciones)
                .then(res => {
                    if (!res) {
                        console.log(`Error: ${res.status}`)
                    } else {
                        res.json().then(data => {
                            this.Items = data

                            this.generarOpciones()
                        })
                    }
                })
        },
        async Auditar(dato) {
            this.movimientosItem = []

            const url = this.direccionApi+'auditar/'+dato.id
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                }
            }

            fetch(url, reqOpciones)
                .then(res => {
                    if (!res) {
                        console.log(`Error: ${res.status}`)
                    } else {
                        res.json().then(data => {
                            console.log(data)
                            for(let i = 0;i<data.length;i++){
                                const detalle = data[i].detalle
                                const detalle2 = data[i].detalle2
                                if(data[i].salida === 'salida'){
                                    
                                data[i].detalleFinal = `Equipo: ${detalle} Mecanico: ${detalle2}`
                                }else if(data[i].salida === 'ingreso'){
                                    
                                data[i].detalleFinal = `Factura: ${detalle} Costo: $${detalle2}`
                                }
                                
                               const fecha = (data[i].fecha?data[i].fecha.split('T')[0]:'NO')
                                data[i].fecha = fecha
                            }
                            this.movimientosItem = data
                        })
                    }
                })
        },
        async pasoDatos(Datos) {

            this.datosEditar = Datos
            this.nombre = Datos.nombre
            this.descripcion = Datos.descripcion
            this.stock = Datos.stock
            this.stockMinimo = Datos.stockMinimo
            this.auditado = false
            this.fechaInicial = null
            this.fechaFinal = null
            this.movimientosItem = []
            this.stockAgregado = null
            this.hayCambios = false
            if (Datos.activo == true) {
                this.itemActivo = true
            } else {
                this.itemActivo = false
            }
        },
        async vaciarVariables() {
            this.nombre = null
            this.descripcion = null
            this.stock = null
            this.stockMinimo = null
        },
        async formatoRUT() {
            const rut = clean(this.RUTproveedor)
            const rutFormateado = format(rut, { dots: false })
            this.RUTproveedor = rutFormateado
            this.rutValido = validate(rutFormateado)
        },
        async agregarColumna() {
            const objetoNuevo = {
                item: null,
                cantidad: 1,
                valorU: 0,
                valorT: 0
            }
            this.cantidadCompra.push(objetoNuevo)
        },
        async preAgregarCompra() {
            this.cantidadCompra = []
            this.agregarColumna()
            this.generarOpciones()
            this.listaTipoPago = [
                { Tipo: 'Contado', valor: 0 },
                { Tipo: 'Débito', valor: 0 },
                { Tipo: 'Crédito', valor: 0 },
                { Tipo: 'Cheque', valor: 0 }
            ]
            this.valorRestante = 0
        },
        async generarOpciones() {
            var Items = this.Items
            this.opciones = []
            Items.sort((a, b) => {
                const nombreA = a.nombre.toLowerCase(); // Convertir a minúsculas para ordenar sin importar mayúsculas/minúsculas
                const nombreB = b.nombre.toLowerCase();

                if (nombreA < nombreB) {
                    return -1; // a debe venir antes que b en el orden
                }
                if (nombreA > nombreB) {
                    return 1; // a debe venir después que b en el orden
                }
                return 0; // a y b son iguales en términos de ordenación
            });
            Items.forEach(elem => {
                var opcion = {
                    id: elem.id,
                    nombre: elem.nombre
                }
                this.opciones.push(opcion)
            })
        },
        eliminarRow(index) {
            this.cantidadCompra.splice(index, 1);
        },
        async total(index) {
            const cantidad = this.cantidadCompra[index].cantidad
            const valorU = this.cantidadCompra[index].valorU
            const total = cantidad * valorU
            this.cantidadCompra[index].valorT = total
            this.modificarValoresFinal()
        },
        async modificarValoresFinal() {
            const lista = this.cantidadCompra
            var suma = 0
            lista.forEach(elem => {
                suma += parseInt(elem.valorT)
            })
            const iva = parseInt(Math.round(suma * 0.19))
            const total = Math.round(suma + iva)
            this.valorNeto = suma
            this.IVA = iva
            this.valorTotal = total
            this.valorRestante = parseInt(total)
        },
        async restarTotal() {
            var restante = parseInt(this.valorTotal)
            const lista = this.listaTipoPago
            lista.forEach(ele => {
                if (ele.valor === '') {
                    ele.valor = 0
                }
                restante = restante - parseInt(ele.valor)
            })
            this.valorRestante = restante
        },
        async buscarProveedor() {
            const rut = this.RUTproveedor

            const url = this.direccionApi + 'proveedor/' + rut

            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                }
            }

            fetch(url, reqOpciones)
                .then(res => {
                    if (!res) {
                        console.log(`Error: ${res.status}`)
                    } else {
                        res.json().then(data => {
                            if (data.length == 0) {
                                Swal.fire({
                                    icon: 'info',
                                    title: 'Proveedor no Registrado'
                                })
                            } else {
                                this.nombreProveedor = data[0].proveedor
                            }
                        })
                    }
                })
        }
    },
    mounted() {
         var token = localStorage.getItem("token");
          var tipo = localStorage.getItem("tipo");
           this.tk=localStorage.getItem("token");
           this.usuario=localStorage.getItem("usuario");
         // console.log(token,tipo); 
          if(!token || tipo!=0){
            this.$router.push('/');
          }

          
        const direccionApi = 'https://api.trosales.cl/'
        //const direccionApi = 'http://localhost:3000/'
        this.direccionApi = direccionApi

        const url = this.direccionApi + 'items'
        const reqOpciones = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                    'Authorization':this.tk,
            }
        }

        fetch(url, reqOpciones)
            .then(res => {
                if (!res) {
                    console.log(`Error: ${res.status}`)
                } else {
                    res.json().then(data => {
                        this.Items = data
                    })
                }
            })
    }
}
</script>

<style>
h1 {
    color: #26AF4A;
    font-weight: bold;
    font-size: xx-large;
}

h2 {
    color: #26AF4A;
    font-weight: bold;
    font-size: x-large;
}

span {
    color: #26AF4A;
    font-size: large;
    font-weight: 500;
}

.form-check-input:checked {
    background-color: #26AF4A;
    border-color: #26AF4A;
}

.container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 40px;
    margin: auto;
    text-align: center;
}

/** Switch
 -------------------------------------*/

.switch input {
    position: absolute;
    opacity: 0;
}

/**
 * 1. Adjust this to size
 */

.switch {
    display: inline-block;
    font-size: 20px;
    /* 1 */
    height: 1em;
    width: 2em;
    background: #BDB9A6;
    border-radius: 1em;
    margin-left: 1%;
    margin-right: 1%;
}

.switch div {
    height: 1em;
    width: 1em;
    border-radius: 1em;
    background: #FFF;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
}

.switch input:checked+div {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}


/* Define una clase personalizada para el cuadro de entrada de contraseña */
.custom-input-class {
    z-index: 9999;
    /* Establece un índice z alto para asegurarse de que esté por encima de otras capas, como modales */
}
</style>