<template>
    <div class="content">
        <br>
        <div id="contenedorlogin" class="container-fluid col-md-6 shadow" style="background: white; border-radius: 10px;">
            <br>
            <h3 class="text-center" style="color: #26AF4A; font-family: Montserrat, sans-serif;">
                <b>Transportes Rosales</b>
            </h3>
            <div class="d-flex justify-content-center">
                <div class="row">
                    <div class="col">
                      <img width="150px" style="margin-left: 1%;" class="img-fluid" src="../assets/logo.jpeg">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="row">
                    <div class="col">
                        <img src="../assets/logoLogin.jpg" width="400" alt="Responsive image" class="img-fluid">
                        <br>
                    </div>
                </div>
            </div>
            <div id="logCorreo">
                <form @submit.prevent="Login">
                    <div class="container-fluid col-md-10">

                        <h2 style="    color: #26AF4A; font-weight: bold;font-size: x-large;">Rut:</h2>
                        <div class="d-flex justify-content-center mb-2 mt-2">
                            <input type="text" placeholder="Ingrese Rut" @input="formatoRUT" id="correo"
                                class="form-control form-control-lg" v-model="Rut"
                                style="border: 2px #26AF4A solid; font-weight: 600;" maxlength="10">

                        </div>
                        <h2 style="    color: #26AF4A; font-weight: bold;font-size: x-large;">Contraseña:</h2>
                        <div class="d-flex justify-content-center">
                            <input type="password" placeholder="Ingrese Clave" id="clave"
                                class="form-control form-control-lg" v-model="Clave"
                                style="border: 2px #26AF4A solid; font-weight: 600;">
                        </div>
                    </div>

                    <br>
                    <div class="d-flex justify-content-center">
                        <div class="row">
                            <div class="col-sm">
                                <button id="btnBuscar" class="btn" type="submit"
                                    style="background-color: #26AF4A;color: white; width: 100%; font-weight: bold;">
                                    Ingresar
                                </button>
                            </div>
                        </div>
                    </div><br>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import { format, clean, validate } from 'rut.js'
import Swal from 'sweetalert2'
export default {
    name: 'Login',
    comments: {},
    data: function () {
        return {
            Rut: null, Clave: null,
             urlBd:'https://api.trosales.cl/',
        }
    },
    methods: {
          
            Login() {
                 if(validate(this.Rut)){
                    console.log(this.Rut,this.Clave)
                 var x = {
                        rut:this.Rut,
                        password: this.Clave
                        
                    };
                
               // if(persona!=""){
                    fetch(this.urlBd+"login", {
                    method: 'post',
                    headers: {
                         'Content-Type': 'application/json' // Establece el tipo de contenido como JSON
                        },
                    body: JSON.stringify(x) 
                
               
                    })
                    .then(response => response.json())
                    .then(data => {
                       console.log(data)
                        if(data.token){
                            //console.log("redirigir a menu")
                            localStorage.setItem("token", data.token);
                           try{
                            if(data.tipo==1){
                                this.$router.push('/chofer');
                                localStorage.setItem("tipo", data.tipo);
                                localStorage.setItem("usuario", data.usuario);
                            }else if(data.tipo==0){
                                 this.$router.push('/alertas');
                                localStorage.setItem("tipo", data.tipo);
                                localStorage.setItem("usuario", data.usuario);
                            }
                           }catch(error){
                            //console.log(error)
                           }
                           if(document.getElementById("clave").value=="1234"){
                                localStorage.setItem("cambioClave", "SI");
                           }else{
                                localStorage.setItem("cambioClave", "NO");
                           }
                           
                        }else{
                            Swal.fire({
                                title: "Error",
                                text:"Usuario o contraseña incorrecto",
                                icon: "warning",
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                       Swal.fire({
                            title: "Error",
                            text:"Usuario o contraseña incorrecto",
                            icon: "warning",
                        })
                    });
              /*  }else{
                    Swal.fire({
                                title: "Aviso",
                                text:"Debe ingresar un nombre o parte de él.",
                                icon: "info",
                    })
                }*/
                 }else{
                    Swal.fire({
                                title: "Error",
                                text:"Ingrese un RUT válido",
                                icon: "warning",
                            })
                 }
            },
             async formatoRUT() {
            const rut = clean(this.Rut)
            const rutFormateado = format(rut, { dots: false })
            this.Rut = rutFormateado
        },
      
    },
    mounted() {

    }
}
</script>

<style>
h2 {
    color: #26AF4A;
    font-weight: bold;
    font-size: x-large;
}

</style>