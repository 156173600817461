<template>
    <div class="contenido">
        <nav class="navbar navbar-expand-lg navbar-light shadow d-print-none" style="background-color: rgb(255,255,255);">
            <a class="navbar-brand" href="/home">
                <img width="150px" style="margin-left: 1%;" class="img-fluid" src="../assets/logo.jpeg">

            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
                style="color: white;background-color: white; margin-right: 1%;">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown" style="font-size: large;">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" href="/alertas"><b-icon style=" color:#27AE4A" icon="house"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Alertas</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/trabajos"><b-icon style=" color:#27AE4A" icon="tools"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Trabajos</span></a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="/equipos"><b-icon style=" color:#27AE4A" icon="truck"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A;"> Equipos</span></a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="/inventario"><b-icon style=" color:#27AE4A" icon="box-seam"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Inventario</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/proyectos"><b-icon style=" color:#27AE4A" icon="briefcase"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Proyectos</span></a>
                    </li>
                     <li class="nav-item">
                        <a class="nav-link" href="/usuarios"><b-icon style=" color:#27AE4A" icon="person"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Usuarios</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/cotizaciones"><b-icon style=" color:#27AE4A" icon="file-ruled"
                                aria-hidden="true"></b-icon>
                            <span style=" color:#27AE4A"> Cotizaciones</span></a>
                    </li>
                </ul>
            </div>
        </nav>

         <div class="container-fluid col-md-11 shadow mt-3 d-print-none"
            style="background-color: white; border-radius: 10px;">
            <div class="d-flex justify-content-center">
                <h1 style="color:#26AF4A;font-weight: bold; font-size: xx-large;">Cotizaciones</h1>
            </div>
            <div class="table-responsive" id="tabla">
                <table class="table table-striped table-bordered" style="display.block">
                    <thead style="position: sticky;top: 0;">
                        <tr>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;"> N°
                                Cotización</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Cliente
                            </th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;"> Rut
                                Cliente</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Ciudad</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Fecha</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Total</th>
                            <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                Detalles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Cotizaciones">
                            <td>{{ datos.id }}</td>
                            <td>{{ datos.nombre }}</td>
                            <td>{{ datos.rut }}</td>
                           <td>{{ datos.ciudad }}</td>
                            <td>{{ datos.fecha.split("T")[0] }}</td>
                            <td>${{ datos.total }}</td>
                            <td>
                                <button v-b-modal.modal-Detalles class="btn" @click="pasoDatos(datos)"
                                    style="background-color: #26AF4A;color: white;">
                                    <b-icon icon="eye-fill" aria-hidden="true"></b-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-center" style="margin-top: 1%;padding-bottom: 1%;">
                <button @click="vaciarVariables()" class="btn"
                    style="background-color: #26AF4A; color: white;font-weight: 600;" v-b-modal.modal-Cotizacion>
                    Crear Cotización
                </button>
            </div>
            <b-modal id="modal-Cotizacion" size="xl" hide-footer hide-header>
                <div class="d-flex justify-content-center">
                    <h1 style="color:#26AF4A;font-weight: bold; font-size: xx-large;">Crear Cotización</h1>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Rut</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="Rut" class="form-control form-control-lg" name="Rut" required
                                maxlength="12" @input="formatoRUT" v-model="Rut" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Nombre</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="Nombre" class="form-control form-control-lg" name="Nombre" required
                                v-model="Cliente" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Telefono</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" maxlength="12" id="Telefono" class="form-control form-control-lg"
                                name="Telefono" required v-model="Telefono" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="d-flex justify-content-left">
                            <span>Ciudad</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="Ciudad" class="form-control form-control-lg" name="Ciudad" required
                                v-model="Ciudad" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span>Dirección</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="Direccion" class="form-control form-control-lg" name="Direccion" required
                                v-model="Direccion" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="d-flex justify-content-left">
                            <span>Entrega</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="Entrega" class="form-control form-control-lg" name="Entrega" required
                                v-model="Entrega" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Giro</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="Giro" class="form-control form-control-lg" name="Giro" required
                                v-model="Giro" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Condición de Pago</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="CondicionPago" class="form-control form-control-lg" name="CondicionPago"
                                required v-model="CondicionPago" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Fecha</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="date" id="fechaCotización" class="form-control form-control-lg"
                                name="fechaCotización" required v-model="fechaCotización"
                                style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="table-responsive mb-2 mt-2" id="tabla">
                    <table class="table table-striped table-bordered">
                        <thead style="position: sticky;top: 0">
                            <tr>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Item
                                </th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Cantidad
                                </th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Unidad
                                </th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Descripción</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Precio Neto Unitario</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Total Neto Diario</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Quitar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="index" v-for="(datos, index) in Items">

                                <td v-if="datos.esSubItem === false">
                                    <input @input="calcularTotalItem(index)" min="1" type="number" class="form-control"
                                        style="font-weight: bold;" v-model="Items[index].item">
                                </td>
                                <td v-else>
                                    <input @input="calcularTotalItem(index)" min="1" type="number" class="form-control"
                                        v-model="Items[index].item">
                                </td>

                                <td v-if="datos.esSubItem === false">
                                    <input type="number" class="form-control" style="font-weight: bold;"
                                        v-model="Items[index].cantidad">
                                </td>
                                <td v-else>
                                    <input type="number" class="form-control" v-model="Items[index].cantidad">
                                </td>

                                <td v-if="datos.esSubItem === false">
                                    <input type="number" class="form-control" style="font-weight: bold;"
                                        v-model="Items[index].unidad">
                                </td>
                                <td v-else>
                                    <input type="number" class="form-control" v-model="Items[index].unidad">
                                </td>

                                <td v-if="datos.esSubItem === false">
                                    <input class="form-control" style="font-weight: bold;"
                                        v-model="Items[index].descripcion">
                                </td>
                                <td v-else>
                                    <input class="form-control" v-model="Items[index].descripcion">
                                </td>

                                <td v-if="datos.esSubItem === false">
                                    <input @input="calcularTotalItem(index)" type="number" class="form-control"
                                        style="font-weight: bold;" v-model="Items[index].precioUnitario">
                                </td>
                                <td v-else>
                                    <input @input="calcularTotalItem(index)" type="number" class="form-control"
                                        v-model="Items[index].precioUnitario">
                                </td>

                                <td v-if="datos.esSubItem === false">
                                    <input @input="calcular" type="number" class="form-control" style="font-weight: bold;"
                                        v-model="Items[index].total">
                                </td>
                                <td v-else>
                                    <input @input="calcular" type="number" class="form-control"
                                        v-model="Items[index].total">
                                </td>

                                <td>
                                    <button class="btn" @click="quitar(index)" style="background-color: red;color: white;">
                                        <b-icon icon="trash" aria-hidden="true"></b-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <button class="btn  mb-1" type="button" @click="agregarItem(false)"
                            style="background-color: #26AF4A;color: white;width: 100%; font-weight: bold;">
                            Agregar Item
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button class="btn  mb-1" type="button" @click="agregarItem(true)"
                            style="background-color: #26AF4A;color: white;width: 100%; font-weight: bold;">
                            Agregar SubItem
                        </button>
                    </div>
                </div>
                <div class="d-flex justify-content-left mt-2 ">
                    <span>Nota</span>
                </div>
                <textarea class="form-control mb-2" rows="2" v-model="Nota" style=" border:1px #26AF4A solid;"></textarea>
                <div class="row">
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Neto</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="number" disabled id="Neto" class="form-control form-control-lg" name="Neto"
                                v-model="Neto" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>IVA</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="number" disabled id="IVA" class="form-control form-control-lg" name="IVA"
                                v-model="IVA" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Total</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="number" disabled id="Total" class="form-control form-control-lg" name="Total"
                                v-model="Total" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-2">
                    <button @click="registrarCotizacion()" class="btn"
                        style="background-color: #26AF4A; color: white;font-weight: bold; width: 100%;">
                        Registrar Cotización
                    </button>
                </div>
            </b-modal>
            <b-modal id="modal-Detalles" size="xl" hide-footer hide-header>
                <div class="d-flex justify-content-center">
                    <h1 style="color:#26AF4A;font-weight: bold; font-size: xx-large;">Cotización {{ Id }}</h1>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Rut</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="Rut" class="form-control form-control-lg" name="Rut" disabled
                                maxlength="12" @input="formatoRUT" v-model="Rut" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Nombre</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="Nombre" class="form-control form-control-lg" name="Nombre" disabled
                                v-model="Cliente" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Telefono</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" maxlength="12" id="Telefono" class="form-control form-control-lg"
                                name="Telefono" disabled v-model="Telefono" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="d-flex justify-content-left">
                            <span>Ciudad</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="Ciudad" class="form-control form-control-lg" name="Ciudad" disabled
                                v-model="Ciudad" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span>Dirección</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="Direccion" class="form-control form-control-lg" name="Direccion" disabled
                                v-model="Direccion" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="d-flex justify-content-left">
                            <span>Entrega</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="Entrega" class="form-control form-control-lg" name="Entrega" disabled
                                v-model="Entrega" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Giro</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="Giro" class="form-control form-control-lg" name="Giro" disabled
                                v-model="Giro" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Condición de Pago</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="text" id="CondicionPago" class="form-control form-control-lg" name="CondicionPago"
                                disabled v-model="CondicionPago" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Fecha</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="date" id="fechaCotización" class="form-control form-control-lg"
                                name="fechaCotización" disabled v-model="fechaCotización"
                                style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="table-responsive mb-2 mt-2" id="tabla">
                    <table class="table table-striped table-bordered">
                        <thead style="position: sticky;top: 0">
                            <tr>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Item
                                </th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Cantidad
                                </th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Unidad
                                </th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Descripción</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Precio Neto Unitario</th>
                                <th style="background-color:#26AF4A;color:white;font-weight: bold;justify-content: center;">
                                    Total Neto Diario</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="index" v-for="(datos, index) in ItemsCoti">

                                <td v-if="datos.esSubItem === false">
                                    <input disabled type="number" class="form-control" style="font-weight: bold;"
                                        v-model="ItemsCoti[index].item">
                                </td>
                                <td v-else>
                                    <input disabled type="number" class="form-control" v-model="ItemsCoti[index].item">
                                </td>

                                <td v-if="datos.esSubItem === false">
                                    <input disabled type="number" class="form-control" style="font-weight: bold;"
                                        v-model="ItemsCoti[index].cantidad">
                                </td>
                                <td v-else>
                                    <input disabled type="number" class="form-control" v-model="ItemsCoti[index].cantidad">
                                </td>

                                <td v-if="datos.esSubItem === false">
                                    <input disabled type="number" class="form-control" style="font-weight: bold;"
                                        v-model="ItemsCoti[index].unidad">
                                </td>
                                <td v-else>
                                    <input disabled type="number" class="form-control" v-model="ItemsCoti[index].unidad">
                                </td>

                                <td v-if="datos.esSubItem === false">
                                    <input disabled class="form-control" style="font-weight: bold;"
                                        v-model="ItemsCoti[index].descripcion">
                                </td>
                                <td v-else>
                                    <input disabled class="form-control" v-model="ItemsCoti[index].descripcion">
                                </td>

                                <td v-if="datos.esSubItem === false">
                                    <input disabled type="number" class="form-control" style="font-weight: bold;"
                                        v-model="ItemsCoti[index].precioUnitario">
                                </td>
                                <td v-else>
                                    <input disabled type="number" class="form-control"
                                        v-model="ItemsCoti[index].precioUnitario">
                                </td>

                                <td v-if="datos.esSubItem === false">
                                    <input disabled type="number" class="form-control" style="font-weight: bold;"
                                        v-model="ItemsCoti[index].total">
                                </td>
                                <td v-else>
                                    <input disabled type="number" class="form-control" v-model="ItemsCoti[index].total">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex justify-content-left mt-2 ">
                    <span>Nota</span>
                </div>
                <textarea class="form-control mb-2" rows="2" disabled v-model="Nota"
                    style=" border:1px #26AF4A solid;"></textarea>
                <div class="row">
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Neto</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="number" disabled id="Neto" class="form-control form-control-lg" name="Neto"
                                v-model="Neto" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>IVA</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="number" disabled id="IVA" class="form-control form-control-lg" name="IVA"
                                v-model="IVA" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-left">
                            <span>Total</span>
                        </div>
                        <div class="d-flex justify-content-left">
                            <input type="number" disabled id="Total" class="form-control form-control-lg" name="Total"
                                v-model="Total" style="border: 1px #26AF4A  solid;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-2">
                    <button @click="Imprimir()" class="btn"
                        style="background-color: #26AF4A; color: white;font-weight: bold; width: 100%;">
                        Imprimir Cotización
                    </button>
                </div>
            </b-modal>
        </div>
        <div class="zonaImpresion d-none d-print-block" style="height: 100%">
            <img width="150px" style="margin-left: 1%;margin-top:1%" class="img-fluid" src="../assets/logo.jpeg">
            <div class="d-flex justify-content-center">
                <h5 style="font-weight: 600;">Transportes Rosales Limitada</h5>
            </div>
            <div class="d-flex justify-content-left">
                <h6 style="font-weight: 500;font-size: small;"><strong>CONTACTO:</strong> +56 9 98844670</h6>
            </div>
            <div class="d-flex justify-content-left">
                <h6 style="font-weight: 500;font-size: small;"><strong>RUT:</strong> 76.656.560-3</h6>
            </div>
            <div class="d-flex justify-content-left">
                <h6 style="font-weight: 500;font-size: small;"><strong>GIRO:</strong> {{ Giro }}</h6>
            </div>
            <div class="d-flex justify-content-left">
                <h6 style="font-weight:500;font-size: small;"><strong>DIRECCIÓN:</strong> ANDRES BALLESTEROS
                    S/N-BOBADILLA-SAN JAVIER </h6>
            </div>
            <div class="d-flex justify-content-left">
                <h6 style="font-weight: 500;font-size: small;"><strong>E-MAIL:</strong> TRANSRCR@GMAIL.COM</h6>
            </div>
            <div class="d-flex justify-content-center">
                <h5 style="font-weight: 600;">COTIZACIÓN N°{{ Id }}</h5>

            </div>
            <div class="d-flex justify-content-center mb-1">
                <h6 style="font-weight: 600;">{{ fechaCotización }}</h6>
            </div>
            <div style="border-top: 3px #26AF4A solid;"></div>

            <div class="info mt-2" style="padding-left: 2%; padding-right: 2%;">

                <div class="d-flex flex-wrap text-nowrap">
                    <span class="strong">SEÑORES</span>
                    <span class="dato">{{ Cliente }}</span>
                    <span class="strong">RUT:</span>
                    <span class="dato">{{ Rut }}</span>
                    <span class="strong">FONO:</span>
                    <span class="dato">{{ Telefono }}</span>
                    <span class="strong">FECHA</span>
                    <span class="dato">{{ fechaCotización }}</span>
                </div>
                <div class="d-flex flex-wrap text-nowrap">
                    <span class="strong">DIRECCIÓN:</span>
                    <span class="dato">{{ Direccion }}</span>
                    <span class="strong">CIUDAD:</span>
                    <span class="dato">{{ Ciudad }}</span>
                    <span class="strong">PROVEEDOR</span>
                    <span class="dato">T.ROSALES LTDA.</span>

                </div>
                <div class="d-flex flex-wrap text-nowrap">
                    <span class="strong">ATENCION</span>
                    <span class="dato">{{ Atencion }}</span>
                    <span class="strong" style="margin-right: -3%;">COND. DE PAGO <br>
                        <div style="font-size: xx-small;font-weight: bold;">VALORES NETO MÁS 19% IVA</div>
                    </span>
                    <span class="dato" style="margin-left: 0;">{{ CondicionPago }}</span>
                    <span class="strong">ENTREGA</span>
                    <span class="dato">{{ Entrega }}</span>

                </div>
            </div>
            <div class="d-flex justify-content-left mt-1">
                <span style="font-weight:bold;font-size: xx-small;color: black;padding-left: 2%; ">
                    Tenemos el agrado de cotizar a Ud. lo siguiente:
                </span>
            </div>
            <div class="table-responsive mt-2" id="tabla" style="margin-bottom: 0;padding-left: 1%;padding-right: 1%;">
                <table class="table table-bordered">
                    <thead style="position: sticky;top: 0">
                        <tr>
                            <th
                                style=";border: 2px black solid;font-weight: bold;font-size: x-small;background-color: #e7e7e7;">
                                ITEM
                            </th>
                            <th
                                style="border: 2px black solid;font-weight: bold;font-size: x-small;background-color: #e7e7e7;">
                                CANT.
                            </th>
                            <th
                                style="border: 2px black solid;font-weight: bold;font-size: x-small;background-color: #e7e7e7;">
                                UN
                            </th>
                            <th
                                style="border: 2px black solid;font-weight: bold;font-size: x-small;background-color: #e7e7e7;">
                                DESCRIPCIÓN</th>
                            <th
                                style="border: 2px black solid;font-weight: bold;font-size: x-small;background-color: #e7e7e7;">
                                Precio Neto Unitario</th>
                            <th
                                style="border: 2px black solid;font-weight: bold;font-size: x-small;background-color: #e7e7e7;">
                                Total Neto Diario</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in ItemsCoti">

                            <td style="border:1px black solid; font-size:x-small;background-color:white"
                                v-if="datos.esSubItem === false">
                                <strong>{{ datos.item }}</strong>
                            </td>
                            <td style="border:1px black solid; font-size:x-small;background-color:white" v-else>
                                {{ datos.item }} </td>

                            <td style="border:1px black solid; font-size:x-small;background-color:white"
                                v-if="datos.esSubItem === false">
                                <strong class="strongTable">{{ datos.cantidad }}</strong>
                            </td>
                            <td style="border:1px black solid; font-size:x-small;background-color:white" v-else>
                                {{ datos.cantidad }} </td>

                            <td style="border:1px black solid; font-size:x-small;background-color:white"
                                v-if="datos.esSubItem === false">
                                <strong class="strongTable">{{ datos.unidad }}</strong>
                            </td>
                            <td style="border:1px black solid; font-size:x-small;background-color:white" v-else>
                                {{ datos.unidad }} </td>

                            <td style="border:1px black solid; font-size:x-small;background-color:white"
                                v-if="datos.esSubItem === false">
                                <strong class="strongTable">{{ datos.descripcion }}</strong>
                            </td>
                            <td style="border:1px black solid; font-size:x-small;background-color:white" v-else>
                                {{ datos.descripcion }} </td>

                            <td style="border:1px black solid; font-size:x-small;background-color:white"
                                v-if="datos.esSubItem === false">
                                <strong class="strongTable">{{ datos.precioUnitario }}</strong>
                            </td>
                            <td style="border:1px black solid; font-size:x-small;background-color:white" v-else>
                                {{ datos.precioUnitario }} </td>

                            <td style="border:1px black solid; font-size:x-small;background-color:white"
                                v-if="datos.esSubItem === false">
                                <strong class="strongTable">{{ datos.total }}</strong>
                            </td>
                            <td style="border:1px black solid; font-size:x-small;background-color:white" v-else>
                                {{ datos.total }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="nota"
                style="margin-left: 1%;margin-right: 1%;padding-left: 1%;background-color: yellow;border: 1px black solid;">
                <span class="strong">NOTA:</span>
                <span class="dato">{{ Nota }}</span>
            </div>
            <div class="d-flex justify-content-end">
                <div class="table-responsive mt-2" id="tabla" style="margin-bottom: 0;padding-left: 1%;padding-right: 1%;">
                    <table class="table table-bordered">
                        <tr>
                            <td
                                style="border:1px black solid;padding-left: 100px;padding-right: 100px;font-size: medium;font-weight: 500;">
                                NETO</td>
                            <td
                                style="border:1px black solid;padding-left: 50px;padding-right: 50px;font-size: medium;font-weight: bold;">
                                $ {{Neto}}</td>
                        </tr>
                        <tr>
                            <td
                                style="border:1px black solid;padding-left: 100px;padding-right: 100px;font-size: medium;font-weight: 500;">
                                19% IVA</td>
                            <td
                                style="border:1px black solid;padding-left: 50px;padding-right: 50px;font-size: medium;font-weight: bold;">
                                $ {{IVA}}</td>
                        </tr>
                        <tr>
                            <td
                                style="border:1px black solid;padding-left: 100px;padding-right: 100px;font-size: medium;font-weight: 500;">
                                TOTAL</td>
                            <td
                                style="border:1px black solid;padding-left: 50px;padding-right: 50px;font-size: medium;font-weight: bold;">
                                $ {{Total}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="abajo" style=" page-break-inside: avoid;">
                <div class="d-flex justify-content-between " style="margin-left:1%;margin-right: 1%;">

                    <div>
                        <div class="d-flex justify-content-center">
                            <img width="250" class="img-fluid" src="../assets/firmaCotizacion.png">
                        </div>
                        <div class="d-flex justify-content-center">
                            <h6>TRANSPORTES ROSALES LTDA.</h6>
                        </div>
                        <span style="color:black">__________________________________________________</span>
                        <div class="d-flex justify-content-center">
                            <h6>FIRMA PROVEEDOR</h6>
                        </div>
                    </div>
                    <div class="d-flex align-items-end">
                        <div>
                            <span style="color:black">__________________________________________________</span>
                            <div class="d-flex justify-content-center">
                                <h6>FIRMA CLIENTE</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <h6 style="text-decoration: underline;font-weight: 600;">Validez de Cotización: 30 días</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { clean, format, validate } from 'rut.js'
import Swal from 'sweetalert2';

export default {

    name: 'Inventario',
    components: {

    },
    computed: {

    },
    data: function () {
        return {
            Cotizaciones: [], AuxCotizaciones: [], Rut: null, Telefono: null,
            fechaCotización: null, Direccion: null, Ciudad: null,
            Entrega: null, CondicionPago: null, Cliente: null,
            Items: [], Nota: null, Neto: null, IVA: null, Total: null,
            Id: null, Atencion: null, Giro: null, direccionApi: null,tk:'',
           usuario:'',ItemsCoti:[]
        }
    },
    methods: {

        async obtenerCotizaciones() {
            //OBJETO DE PRUEBA
            // const obj = {
            //     id: 2363,
            //     cliente: 'Cliente de Prueba',
            //     rut: '78.203.080-4',
            //     telefono: '+56990703290',
            //     fechaCotización: '2023-11-12',
            //     direccion: 'ALCANTARA 271 OF 301 LAS CONDES',
            //     ciudad: 'Santiago',
            //     atencion: 'Antonio Cruzat',
            //     entrega: 'Antuco',
            //     condicionPago: '30 días',
            //     nota: 'Nota de ejemplo',
            //     neto: 160000,
            //     iva: 30400,
            //     total: 190400,
            //     giro: 'TRANSPORTE DE CARGA POR CARRETERA',
            //     items: [
            //         {
            //             item: 1,
            //             cantidad: 1,
            //             unidad: 1,
            //             descripcion: 'Arriendo camiones diario',
            //             precioUnitario: 160000,
            //             total: 160000,
            //             esSubItem: false
            //         },
            //         {
            //             item: null,
            //             cantidad: null,
            //             unidad: null,
            //             descripcion: 'No sé que poner aca',
            //             precioUnitario: null,
            //             total: null,
            //             esSubItem: true
            //         }
            //     ]
            // }
            const url = this.direccionApi + 'cotizaciones'
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        throw Error('Error en la base de datos')
                    }
                    res.json().then(data => {
                        this.Cotizaciones = data

                    })
                }).catch(error => {
                    console.error(error)
                })
        },
        async registrarCotizacion() {
            const datos = {
                rut: this.Rut,
                nombre: this.Cliente,
                telefono: this.Telefono,
                ciudad: this.Ciudad,
                direccion: this.Direccion,
                entrega: this.Entrega,
                giro: this.Giro,
                condicionPago: this.CondicionPago,
                fecha: this.fechaCotización,
                listaItems: this.Items,
                nota: this.Nota,
                neto: this.Neto,
                iva: this.IVA,
                total: this.Total
            }
            const url = this.direccionApi + 'cotizaciones'
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':this.tk,
                },
                body: JSON.stringify(datos)
            }
            fetch(url, reqOpciones).then((res) => {
                console.log(res)
                if(!res.ok){
                    Swal.fire({
                        icon:'error',
                        title:'Error en la base de datos',
                    })
                    throw Error ('Error en la base de datos')
                }
                //this.Imprimir()
            }).catch(err => {
                console.log(err)
            })

        },



        async vaciarVariables() {
            this.Rut = null
            this.Telefono = null
            var fechaDeHoy = new Date();
            this.Direccion = null
            this.Ciudad = null
            this.Entrega = null
            this.CondicionPago = null
            this.Cliente = null
            this.Nota = null
            this.Neto = null
            this.IVA = null
            this.Total = null
            this.Giro = null

            var anio = fechaDeHoy.getFullYear();
            var mes = (fechaDeHoy.getMonth() + 1).toString().padStart(2, '0'); // Sumamos 1 al mes porque en js los meses comienzan en 0.
            var dia = fechaDeHoy.getDate().toString().padStart(2, '0');
            var fechaFormateada = anio + '-' + mes + '-' + dia;
            this.fechaCotización = fechaFormateada
            this.Items = []

            const objetoItems = {
                item: 1,
                cantidad: null,
                unidad: null,
                descripcion: null,
                precioUnitario: null,
                total: null,
                esSubItem: false
            }
            this.Items.push(objetoItems)
        },
        async formatoRUT() {
            const rut = clean(this.Rut)
            const rutFormateado = format(rut)
            this.Rut = rutFormateado
        },
        async RegistrarUsuario() {
            if (this.TipoUsuario === 'no') {
                Swal.fire({
                    icon: 'warning',
                    title: 'Seleccione tipo de usuario'
                })
                return
            }
            if (!validate(this.Rut)) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Rut Inválido',
                    text: 'Revise Rut ingresado y vuelva a intentar'
                })
                return
            }
            const datos = {
                rut: this.Rut,
                nombre: this.Nombre.toUpperCase(),
                tipoUsuario: this.TipoUsuario,
                pass: this.Pass,
                correo: this.Correo.toUpperCase(),
                telefono: this.telefono
            }

            const url = this.direccionApi + 'cotizaciones'

            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(datos)
            }
            console.log(`url: ${url} - reqOpciones: ${reqOpciones}`)
        },
        async filtrar() {
            const tipoFiltro = this.TipoBusqueda
            const ValorBusqueda = this.ValorBusqueda.toUpperCase()
            var lista = this.AuxUsuarios
            if (tipoFiltro == 1) {
                lista = lista.filter(objeto => {
                    if (ValorBusqueda) {
                        return objeto.nombre.includes(ValorBusqueda)
                    }

                    return true
                })
            } else if (tipoFiltro == 2) {
                lista = lista.filter(objeto => {
                    if (ValorBusqueda) {
                        return objeto.rut.includes(ValorBusqueda)
                    }
                    return true
                })
            }

            this.Usuarios = lista
        },

        quitar(index) {
            this.Items.splice(index, 1);
        },
        agregarItem(esSubItem) {
            const objetoNuevo = {
                item: null,
                cantidad: null,
                unidad: null,
                descripcion: null,
                precioUnitario: null,
                total: null,
                esSubItem: esSubItem
            }
            this.Items.push(objetoNuevo)
        },
        async calcular() {
            const lista = this.Items
            var totalNeto = 0
            lista.forEach(element => {
                if (element.total) {
                    totalNeto += parseInt(element.total)
                }
            })
            this.Neto = totalNeto
            this.IVA = totalNeto * 0.19
            this.Total = totalNeto * 1.19
        },
        async pasoDatos(datos) {
            console.log(datos.items)
            this.Id = datos.id
            this.Rut = datos.rut
            this.Cliente = datos.nombre
            this.Direccion = datos.direccion
            this.Ciudad = datos.ciudad
            this.Atencion = datos.atencion
            this.Entrega = datos.entrega
            this.CondicionPago = datos.condicionPago
            this.fechaCotización = datos.fecha.split("T")[0]
            this.Items = datos.items
            this.Nota = datos.nota
            this.Neto = datos.neto
            this.IVA = datos.iva
            this.Total = datos.total
            this.Giro = datos.giro
            this.Telefono = datos.telefono
            this.cargarItemsCoti(datos.id)
        },
        async cargarItemsCoti(id){
            const url = this.direccionApi + 'cargarItemsCoti/'+id
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        throw Error('Error en la base de datos')
                    }
                    res.json().then(data => {
                        console.log(data)
                        this.ItemsCoti = data

                    })
                }).catch(error => {
                    console.error(error)
                })
        },
        Imprimir() {
            this.$bvModal.hide('modal-Cotizacion')
            this.$bvModal.hide('modal-Detalles')

            setTimeout(function () {
                window.print();
            }, 1000);
        },
        async calcularTotalItem(index) {
            const unitario = parseInt(this.Items[index].precioUnitario)
            const item = parseInt(this.Items[index].item)
            console.log(unitario * item)
            this.Items[index].total = unitario * item
            this.calcular()
        }
    },
    mounted() {
        var token = localStorage.getItem("token");
          var tipo = localStorage.getItem("tipo");
           this.tk=localStorage.getItem("token");
           this.usuario=localStorage.getItem("usuario");
         // console.log(token,tipo); 
          if(!token || tipo!=0){
            this.$router.push('/');
          }

         
        const direccionApi = 'https://api.trosales.cl/'
        //const direccionApi = 'http://localhost:3000/'
        this.direccionApi = direccionApi

        this.obtenerCotizaciones()

    }
}
</script>

<style>
h1 {
    color: #26AF4A;
    font-weight: bold;
    font-size: xx-large;
}

h2 {
    color: #26AF4A;
    font-weight: bold;
    font-size: x-large;
}

span {
    color: #26AF4A;
    font-size: large;
    font-weight: 500;
}

@media print {

    .contenido {
        border: 1px black solid;
        background-color: white;
        height: 100%;
    }

    nav {
        margin-left: 1%;
        margin-right: 1%;
    }

    h6 {
        padding-left: 2%;
        color: black;
    }

    h5 {
        color: black
    }

    span {
        color: black;
        font-size: x-small;
    }

    .strong {
        margin-left: 2%;
        margin-right: 2%;
        font-weight: bold;
        font-size: x-small;
        color: black;
    }

    .dato {
        color: black;
        font-size: x-small;
        margin-right: 2%;
        margin-bottom: 1%;
    }


}
</style>